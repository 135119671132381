import React from "react";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { isSponsor, isAdmin, isTeam } from "../Session";

import "./Feed.scss";
import FollowedOrganizations from "./FollowedOrganizations";
import DealsList from "../DealsList";
import RecommendedDeal from "../RecommendedDeal";
import SuggestedOrganizations from "./SuggestedOrganizations";
import {
  useFollowedOrganizations,
  useFollowedOrganizationOffers,
  useFollowedOrganizationOfferGroups,
  useFeaturedTeams,
} from "../../hooks";

function Feed({ firebase, authUser, defaultLayoutSettings }) {
  const { featuredTeams, loading: loadingFeaturedTeams } =
    useFeaturedTeams(firebase);
  const { followedOrganizations, loading: loadingFollowedOrganizations } =
    useFollowedOrganizations(firebase, authUser);
  const {
    followedOrganizationOffers,
    loading: loadingFollowedOrganizationOffers,
  } = useFollowedOrganizationOffers(
    firebase,
    authUser,
    loadingFollowedOrganizations,
    followedOrganizations,
    true
  );
  const {
    followedOrganizationOfferGroups,
    loading: loadingFollowedOrganizationOfferGroups,
  } = useFollowedOrganizationOfferGroups(
    firebase,
    loadingFollowedOrganizations,
    followedOrganizations
  );

  const loadingOffersAndOfferGroups =
    loadingFollowedOrganizationOffers || loadingFollowedOrganizationOfferGroups;

  const sortedListings = [
    ...followedOrganizationOffers,
    ...followedOrganizationOfferGroups,
  ].sort((deal1, deal2) => {
    const dontSwap = -1;
    const swap = 1;

    // Undefined createdAt considered lower than defined createdAt
    if (
      (deal1.createdAt === undefined && deal2.createdAt === undefined) ||
      deal2.createdAt === undefined ||
      deal1.createdAt > deal2.createdAt
    ) {
      return dontSwap;
    }

    return swap;
  });

  const getPortalLink = (user) => {
    if (isSponsor(user) && user.sponsorID) {
      return `/portal/sponsors/${user.sponsorID}/dashboard`;
    } else if (isAdmin(user)) {
      return "/portal/dashboard";
    } else if (isTeam(user) && user.teamID) {
      return `/portal/teams/${user.teamID}/dashboard`;
    } else {
      return "/portal/dashboard";
    }
  };

  return (
    <StickyContainer>
      <div className="component-feed">
        <div className="feed-layout">
          <div className="feed-main-content">
            <FollowedOrganizations
              organizations={followedOrganizations}
              loading={loadingFollowedOrganizations}
            />
            {!loadingOffersAndOfferGroups && sortedListings.length === 0 ? (
              <div className="empty-state-deals">
                Start following some organizations{" "}
                <Link to="/organizations">here</Link> to begin seeing deals in
                your feed.
              </div>
            ) : (
              <>
                <h2>Recommended Deal</h2>
                <RecommendedDeal />
                <h2>Your Deals</h2>
                <DealsList
                  loading={loadingFollowedOrganizationOffers}
                  loadingPlaceholderCount={10}
                  deals={sortedListings}
                  dealSize="feed"
                />
              </>
            )}
          </div>
          <Sticky>
            {({ style }) => (
              <div style={style}>
                <div className="feed-sidebar">
                  <div className="suggested-organizations">
                    <SuggestedOrganizations
                      organizations={featuredTeams}
                      loading={loadingFeaturedTeams}
                    />
                    <div className="compact-footer">
                      <Link
                        to={getPortalLink(authUser)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FanSaves Portal
                      </Link>
                      <a
                        href="https://about.fansaves.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About FanSaves
                      </a>
                      <a
                        href="https://about.fansaves.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Request a Free Demo
                      </a>
                      <br />
                      <Link
                        to="/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </Link>
                      <Link
                        to="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>
                      <a
                        href="https://about.fansaves.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact Us
                      </a>
                      <div className="social-links">
                        <a
                          href="http://www.facebook.com/fansaves"
                          target="_blank"
                        >
                          <i className="social-icon fa fa-facebook" />
                        </a>
                        <a
                          href="http://www.linkedin.com/company/fansaves/"
                          target="_blank"
                        >
                          <i className="social-icon fa fa-linkedin" />
                        </a>
                        <a
                          href="http://www.twitter.com/fan_saves"
                          target="_blank"
                        >
                          <i className="social-icon fa fa-twitter" />
                        </a>
                        <a
                          href="http://www.instagram.com/fansaves"
                          target="_blank"
                        >
                          <i className="social-icon fa fa-instagram" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Sticky>
        </div>
      </div>
    </StickyContainer>
  );
}

export default withFirebase(withAuthentication(Feed));
