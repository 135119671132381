/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../../../containers/Firebase";
import { withEmbedMode } from "../../../containers/EmbedModeContext";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { DefaultContainer } from "../../../containers/UIKit";
import { logEvent, eventNames } from "../../../utilities/analytics";

import Header from "./Header";
import DealsTab from "./DealsTab";
import AboutTab from "./AboutTab";
import ContestsTab from "./ContestsTab";
import SpecialDealsTab from "./SpecialDealsTab";

import "./Show.scss";

class Show extends React.Component {
  state = {
    loadingOrganization: true,
    loadingDeals: true,
    deals: [],
    contestDeals: [],
    dealType: "public",
    selectedTab: "",
    searchTerms: "",
  };

  componentDidMount() {
    this.loadOrganization();
    const urlParams = new URLSearchParams(window.location.search);
    const selectedTab = urlParams.get("tab") || "deals";
    const selectedDealType = urlParams.get("deal_type") || "public";
    this.setState({ selectedTab, dealType: selectedDealType });
  }

  loadOrganization = () => {
    const { firebase, match } = this.props;

    const slugOrKey = match.params.organization_id;

    // Attempt by slug
    const teamsPath = "team/";
    const teamsRef = firebase.database.ref(teamsPath);
    let organization;
    teamsRef
      .orderByChild("slug")
      .startAt(slugOrKey)
      .limitToFirst(1)
      .on("value", (snapshot) => {
        if (snapshot.val() !== null) {
          const results = snapshot.val();
          if (
            Object.values(results)[0] &&
            Object.values(results)[0].slug == slugOrKey
          ) {
            organization = Object.values(results)[0];
            organization.key = Object.keys(results)[0];
          }
        }

        if (organization) {
          logEvent(firebase, eventNames.AE_ORGANIZATIONS_SHOW_VIEW, {
            key: organization.key,
            name: organization.name,
          });
          this.setOrganization(organization);
        } else {
          // Attempt by key
          const teamPath = `team/${slugOrKey}`;
          const teamRef = firebase.database.ref(teamPath);
          teamRef
            .once("value")
            .then((snapshot) => {
              const organization = snapshot.val();
              organization.key = snapshot.key;
              logEvent(firebase, eventNames.AE_ORGANIZATIONS_SHOW_VIEW, {
                key: organization.key,
                name: organization.name,
              });
              this.setOrganization(organization);
            })
            .catch((error) => {
              console.log(`Could not find team with slug or key ${slugOrKey}`);
              this.setState({ error });
            });
        }
      });
  };

  setOrganization = (organization) => {
    this.setState({ organization, loadingOrganization: false }, this.loadDeals);
  };

  loadDeals = () => {
    const { firebase } = this.props;

    const { organization } = this.state;

    const sponsors = [];
    const deals = [];
    const contestDeals = [];
    const hiddenDeals = [];

    const sponsorRequestPromises = [];

    if (organization.sponsors) {
      organization.sponsors.forEach((sponsorKey) => {
        const sponsorPath = `sponsor/${sponsorKey}`;
        const sponsorRef = firebase.database.ref(sponsorPath);
        const sponsorRequestPromise = sponsorRef.once("value");
        sponsorRequestPromises.push(sponsorRequestPromise);
      });

      Promise.all(sponsorRequestPromises).then((sponsorSnapshots) => {
        const offerRequestPromises = [];

        sponsorSnapshots.forEach((sponsorSnapshot) => {
          if (sponsorSnapshot.val() !== null) {
            const sponsor = sponsorSnapshot.val();
            sponsor.key = sponsorSnapshot.key;
            sponsors.push(sponsor);
          }
        });

        /* Sort sponsors alphabetically */
        sponsors.sort((firstSponsor, secondSponsor) => {
          if (firstSponsor.name < secondSponsor.name) {
            return -1;
          }
          if (firstSponsor.name > secondSponsor.name) {
            return 1;
          }
          return 0;
        });

        /* Prepare sponsor offer requests */
        sponsors.forEach((sponsor) => {
          if (sponsor.offers) {
            sponsor.offers.forEach((offer) => {
              const offerPath = `offer/${offer.key}`;
              const offerRef = firebase.database.ref(offerPath);
              const offerRequestPromise = offerRef.once("value");
              offerRequestPromises.push(offerRequestPromise);
            });
          }
        });

        Promise.all(offerRequestPromises).then((offerSnapshots) => {
          offerSnapshots.forEach((offerSnapshot) => {
            if (offerSnapshot.val() !== null) {
              const offer = offerSnapshot.val();
              offer.key = offerSnapshot.key;

              if (offer.active && !offer.contestDeal) {
                deals.push(offer);
              }
              if (offer.contestDeal && offer.published) {
                contestDeals.push(offer);
              }
              if (offer.hiddenDeal) {
                hiddenDeals.push(offer);
              }
            }
          });

          this.setState({
            deals,
            contestDeals,
            hiddenDeals,
            loadingDeals: false,
          });
        });
      });
    } else {
      this.setState({ loadingDeals: false });
    }
  };

  handleTabSelected = (tabName) => {
    let newUrl = `${window.location.pathname}?tab=${tabName}`;
    if (tabName === "deals") {
      newUrl += `&deal_type=${this.state.dealType}`;
    }
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState({ selectedTab: tabName, dealType: "public" });
  };

  handlePublicDealsClicked = (e) => {
    e.preventDefault();
    this.setState({ dealType: "public" });
  };

  handleExclusiveDealsClicked = (e) => {
    e.preventDefault();
    this.setState({ dealType: "exclusive" });
  };

  handleHiddenDealsClicked = (e) => {
    e.preventDefault();
    this.setState({ dealType: "hiddenDeal" });
  };

  publicDeals = () => {
    const { deals } = this.state;
    return deals.filter((deal) => !deal.exclusive && !deal.hiddenDeal);
  };

  exclusiveDeals = () => {
    const { deals } = this.state;
    return deals.filter((deal) => deal.exclusive && !deal.hiddenDeal);
  };

  hiddenDeals = () => {
    const { deals } = this.state;
    return deals.filter((deal) => deal.hiddenDeal);
  };

  render() {
    const { embedMode } = this.props;

    const {
      loadingOrganization,
      organization,
      deals,
      selectedTab,
      contestDeals,
      hiddenDeals,
      dealType,
    } = this.state;

    return (
      <CheckOnboarding>
        <DefaultContainer>
          <div className="views-organizations-show">
            {!loadingOrganization && (
              <>
                {organization.archived ? (
                  <div className="archived">
                    <div className="archived-header">We're sorry!</div>
                    This organization is no longer available.
                    <br />
                    Check out more great organizations{" "}
                    <Link to="/organizations">here</Link>.
                  </div>
                ) : (
                  <>
                    <Header
                      organization={organization}
                      embedMode={embedMode}
                      deals={deals}
                    />

                    <div className="tab-headers">
                      <a
                        href="#"
                        className={`tab-header ${
                          selectedTab === "deals" ? "selected" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleTabSelected("deals");
                        }}
                      >
                        Deals
                      </a>
                      {hiddenDeals && hiddenDeals.length > 0 && (
                        <a
                          href="#"
                          className={`tab-header long ${
                            selectedTab === "specialDeals" ? "selected" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleTabSelected("specialDeals");
                          }}
                        >
                          Special Deals
                        </a>
                      )}
                      {contestDeals && contestDeals.length > 0 && (
                        <a
                          href="#"
                          className={`tab-header ${
                            selectedTab === "contests" ? "selected" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleTabSelected("contests");
                          }}
                        >
                          Contests
                        </a>
                      )}
                      <a
                        href="#"
                        className={`tab-header ${
                          selectedTab === "about" ? "selected" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleTabSelected("about");
                        }}
                      >
                        About
                      </a>
                    </div>

                    {selectedTab === "deals" && (
                      <DealsTab
                        organization={organization}
                        dealType={dealType}
                      />
                    )}
                    {selectedTab === "about" && (
                      <AboutTab organization={organization} />
                    )}
                    {selectedTab === "contests" && (
                      <ContestsTab organization={organization} />
                    )}
                    {selectedTab === "specialDeals" && (
                      <SpecialDealsTab organization={organization} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </DefaultContainer>
      </CheckOnboarding>
    );
  }
}

export default withFirebase(withRouter(withEmbedMode(Show)));
