/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import { withAuthentication } from "../../../../containers/Session";
import queryString from "query-string";
import {
  DashboardButton,
  FlexContainer,
  DashboardFilter,
  SearchBar,
  DashboardTitle,
  SpinLoader,
  MobileMarginContainer,
} from "../../../../assets/fansaves-ui";
import { DashboardStats } from "../../../../containers/UIKit";
import DashboardBusinessCard from "../../../../containers/DashboardBusinessCard";
import styled from "styled-components/macro";
import moment from "moment";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useTeamsStore from "../../../../stores/teamsStore";
import useOffersStore from "../../../../stores/offersStore";

import "../../../../scss/base/typography.scss";

const StyledTeamList = styled(FlexContainer)`
  gap: 20px;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;
  .component-dashboard-filter {
    position: absolute;
    top: -30px;
    left: 0;
    z-index: 2;
  }
`;

const List = ({ firebase, authUser, history }) => {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const teams = useTeamsStore((state) => state.teams);
  const fetchTeams = useTeamsStore((state) => state.fetchTeams);

  const fetchOffers = useOffersStore((state) => state.fetchOffers);
  const offers = useOffersStore((state) => state.offersArray);

  const [selectedTeams, setSelectedTeams] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!teams) {
      fetchTeams(firebase);
    }
  }, [fetchTeams, firebase]);

  useEffect(() => {
    if (!redemptionsFromEvents) {
      fetchRedemptions(firebase);
    }
  }, [fetchRedemptions, firebase]);

  useEffect(() => {
    if (redemptionsFromEvents) {
      setRedemptions(redemptionsFromEvents);
    }
  }, [redemptionsFromEvents]);

  useEffect(() => {
    if (!offers) {
      fetchOffers(firebase);
    }
  }, [fetchOffers, firebase]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const getStats = () => {
    let stats = [
      {
        label: "Total Organizations",
        value: teams ? (teams.length > 0 ? teams.length : 0) : renderLoading(),
      },
      {
        label: "Active Organizations",
        value: teams
          ? teams.filter((team) => !team.archived).length
          : renderLoading(),
      },
      {
        label: "Archived Organizations",
        value: teams
          ? teams.filter((team) => team.archived).length
          : renderLoading(),
      },
      {
        label: "Active Offers",
        value: offers
          ? offers.filter((offer) => offer.active).length
          : renderLoading(),
      },
      {
        label: "Total Redemptions",
        value: redemptions
          ? redemptions.filter(
              (redemption) => redemption.transactionType === "redemption"
            ).length
          : renderLoading(),
      },
      {
        label: "Total Contest Entries",
        value: redemptions
          ? redemptions.filter(
              (redemption) => redemption.transactionType === "contest"
            ).length
          : renderLoading(),
      },
    ];
    return stats;
  };

  const getFilterAndQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const filter = search.filter ? search.filter : null;
    const query = search.query;
    if (filter) {
      setFilter(filter);
    } else {
      setFilter("Alphabetical");
    }
    if (query) {
      setSearchQuery(query);
    }
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (filter) {
      params.filter = filter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };

  useEffect(() => {
    getFilterAndQueryFromSearch();
  }, []);

  useEffect(() => {
    setUrlQueryFromFilters();
  }, [filter, searchQuery]);

  const filterTeams = () => {
    let filteredTeams = [...teams];
    if (!filter) {
      setSelectedTeams(filteredTeams);
    }
    switch (filter) {
      case "Alphabetical":
        filteredTeams = filteredTeams.sort(
          (a, b) =>
            (a.name &&
              a.name.localeCompare(b.name, undefined, { numeric: true })) ||
            /^[0-9]/.test(a.name) - /^[0-9]/.test(b.name)
        );
        break;
      case "NewestToOldest":
        filteredTeams = filteredTeams.sort((a, b) => {
          if (a.archived && !b.archived) return 1;
          else if (!a.archived && b.archived) return -1;
          else if (a.archived && b.archived) return 0;
          else {
            return (
              moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
            );
          }
        });
        break;
      case "OldestToNewest":
        filteredTeams = filteredTeams.sort((a, b) => {
          if (a.archived && !b.archived) return 1;
          else if (!a.archived && b.archived) return -1;
          else if (a.archived && b.archived) return 0;
          else {
            return (
              moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
            );
          }
        });
        break;
      case "SportsTeams":
        filteredTeams = filteredTeams.filter(
          ({ categories }) => categories && categories.includes("Sports Teams")
        );
        break;
      case "Communities":
        filteredTeams = filteredTeams.filter(
          ({ categories }) => categories && categories.includes("Communities")
        );
        break;
      case "MediaInfluencers":
        filteredTeams = filteredTeams.filter(
          ({ categories }) =>
            categories && categories.includes("Media & Influencers")
        );
        break;
      case "UniversitiesColleges":
        filteredTeams = filteredTeams.filter(
          ({ categories }) =>
            categories && categories.includes("Universities & Colleges")
        );
        break;
      case "Events":
        filteredTeams = filteredTeams.filter(
          ({ categories }) => categories && categories.includes("Events")
        );

        break;
      case "Charities":
        filteredTeams = filteredTeams.filter(
          ({ categories }) => categories && categories.includes("Charities")
        );
        break;
      default:
        break;
    }
    setSelectedTeams(filteredTeams);
  };

  const handleSearch = () => {
    let tempTeams = [...teams];
    if (!searchQuery) {
      setSelectedTeams(tempTeams);
    }
    const filteredTeams = tempTeams.filter((team) => {
      return (
        team.name && team.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSelectedTeams(filteredTeams);
  };

  useEffect(() => {
    if (teams && filter) {
      filterTeams();
    }
  }, [teams, filter]);

  useEffect(() => {
    if (teams && searchQuery) {
      handleSearch();
    }
  }, [searchQuery, teams]);

  return (
    <div className="animated fadeIn" id="main">
      <FlexContainer gap="30px" margin="50px 0" alignItems="flex-start">
        <FlexContainer
          direction="horizontal"
          justifyContent="space-between"
          width="100%"
          responsive
        >
          <FlexContainer gap="30px" alignItems="flex-start" width="100%">
            <DashboardTitle
              title="Organizations"
              className="organizations-list-title"
              justifyContent="flex-start"
              backButtonPath={`/portal/dashboard`}
            />
            <DashboardButton
              title="Add an Organization"
              icon="fa-solid:plus"
              size="medium"
              type="green"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/portal/teams/create`);
              }}
              width="220px"
            />
          </FlexContainer>
          <MobileMarginContainer margin="10px">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Organizations"
            />
          </MobileMarginContainer>
        </FlexContainer>
        <DashboardStats stats={getStats()} />
        <StyledTeamList>
          <DashboardFilter
            type="team"
            className="component-dashboard-filter inter-b2"
            onSelect={setFilter}
            initialFilterOption={filter}
          />
          {!teams && !selectedTeams ? (
            <div className="no-organizations">
              <SpinLoader />
            </div>
          ) : selectedTeams && selectedTeams.length > 0 ? (
            selectedTeams.map((team) => (
              <DashboardBusinessCard
                key={team.key + team.name}
                business={team}
                renderButtonsGroup
                teams
              />
            ))
          ) : (
            <div className="no-organizations">No organizations found.</div>
          )}
        </StyledTeamList>
      </FlexContainer>
    </div>
  );
};

export default withFirebase(withAuthentication(List));
