import React, { useState, useEffect } from "react";
import {
  InstantSearch,
  Hits,
  PoweredBy,
  Configure,
} from "react-instantsearch-dom";
import FanPageListItem from "./FanPageListItem";
import { Redirect } from "react-router-dom";
import {
  searchClient,
  FanPagesSelectorSearchBox as SearchBox,
  FanPagesCategoryRefinementList as RefinementList,
  TeamsSelectorResultsWithEmptyState,
  FanPagesSelectorMapView,
} from "../Algolia";
import styled from "styled-components/macro";
import { orderBy } from "lodash";
import { COLORS } from "../../assets/fansaves-ui";
import { Card, CardBody, CardGroup } from "reactstrap";
import OnboardingTitle from "../../views/Onboarding/OnboardingTitle";
import useMobile from "../../hooks/useMobile";
import { withAuthentication } from "../../containers/Session";
import { Button } from "../../containers/UIKit";

const TeamsResultsList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .ais-Hits {
    ul.ais-Hits-list {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-around;
      gap: 10px;

      .ais-Hits-item {
        margin-right: ${({ isMobile }) => (isMobile ? "0px" : "10px")};
        margin-left: ${({ isMobile }) => (isMobile ? "0px" : "10px")};
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
`;

const UserLocation = styled.div`
  width: 100%;
  font-size: 22px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  color: ${COLORS.primaryBlack};
  text-align: center;
  padding-top: 10px;
`;

const FanPagesSelectorTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlue};
  text-align: center;
`;

const FanPagesSelectorSubtitle = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryGreenDark};
  text-align: center;

  .bold {
    font-weight: 700;
  }
`;

const FanPagesSelectorTitleContainer = styled.div`
  flex-direction: column;
  gap: 10px;
`;

const FanPagesSelectorContainer = styled.div`
  margin-top: ${({ isMobile }) => (isMobile ? "0px" : "30px")};
`;

const StyledLeftCartBody = styled(CardBody)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: ${({ isMobile }) => (isMobile ? "300px" : "500px")};
  overflow-y: auto;
  aspect-ratio: 1;
  min-height: ${({ isMobile }) => (isMobile ? "300px" : "500px")};
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryBlue};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${COLORS.primaryWhite};
    border-radius: 10px;
    border: 1px solid ${COLORS.primaryBlue};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const FanPagesSelector = ({ authUser }) => {
  const isMobile = useMobile();
  const [userTeams, setUserTeams] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (authUser && authUser.teams) {
      setUserTeams(authUser.teams);
    }
  }, [authUser]);

  const handleSubmit = () => {
    if (!authUser) {
      setRedirectTo("/");
      return;
    }
    if (userTeams && userTeams.length > 0) {
      setSubmitting(true);
      const redirectPath = window.sessionStorage.getItem(
        "redirect_path_after_onboarding"
      );

      if (redirectPath) {
        window.sessionStorage.removeItem("redirect_path_after_onboarding");
        setSubmitting(false);
        setRedirectTo(redirectPath);
      } else {
        setSubmitting(false);
        setRedirectTo("/organizations");
      }
    } else {
      alert("You must select at least one FanPage to continue.");
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <FanPagesSelectorContainer isMobile={isMobile}>
      <OnboardingTitle
        stepNumber={3}
        renderSubtitle={() => (
          <FanPagesSelectorTitleContainer>
            <FanPagesSelectorTitle>
              Follow FanPages to get free deals from affiliated brands.
            </FanPagesSelectorTitle>
            <FanPagesSelectorSubtitle isMobile={isMobile}>
              We recommend you select a{" "}
              <span className="bold">minimum of three (3)</span> FanPages. Must
              select one (1) to continue.
            </FanPagesSelectorSubtitle>
          </FanPagesSelectorTitleContainer>
        )}
      />
      <InstantSearch searchClient={searchClient} indexName="teams">
        <Configure filters="isBrand:false" />
        <div className="search-bar-container">
          <SearchBox placeholder="Search FanPages..." />
        </div>
        {authUser && authUser.city && authUser.state && (
          <UserLocation>{`${authUser.city}, ${authUser.state}`}</UserLocation>
        )}
        <div>
          <RefinementList
            attribute="categories"
            defaultRefinement={[]}
            transformItems={(items) => orderBy(items, "label", "asc")}
            hideCount
          />
        </div>
        <CardGroup
          style={{
            minhHeight: isMobile ? "90vh" : "500px",
            marginTop: isMobile ? "0px" : "10px",
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
          }}
        >
          <Card style={{ flex: 1 }}>
            <StyledLeftCartBody isMobile={isMobile}>
              <TeamsResultsList isMobile={isMobile}>
                <TeamsSelectorResultsWithEmptyState message="We're sorry, but there were no results for your search.">
                  <Hits hitComponent={FanPageListItem} />
                </TeamsSelectorResultsWithEmptyState>
                <PoweredBy />
              </TeamsResultsList>
              <ButtonContainer>
                <Button
                  className={`green-continue rounded-10 wide ${
                    (submitting || !userTeams || userTeams.length === 0) &&
                    "disabled"
                  }`}
                  onClick={handleSubmit}
                  disabled={submitting || !userTeams || userTeams.length === 0}
                >
                  <StyledButtonTitle
                    disabled={
                      submitting || !userTeams || userTeams.length === 0
                    }
                  >
                    Continue
                  </StyledButtonTitle>
                </Button>
              </ButtonContainer>
            </StyledLeftCartBody>
          </Card>
          <Card style={{ flex: 1 }}>
            <CardBody
              style={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                aspectRatio: 1,
                minHeight: !isMobile ? "500px" : "150px",
              }}
            >
              <FanPagesSelectorMapView
                authUser={authUser}
                mapHeight={isMobile ? "200px" : "500px"}
              />
            </CardBody>
          </Card>
        </CardGroup>
      </InstantSearch>
      <ButtonContainer>
        <Button
          className={`green-continue rounded-10 wide ${
            (submitting || !userTeams || userTeams.length === 0) && "disabled"
          }`}
          onClick={handleSubmit}
          disabled={submitting || !userTeams || userTeams.length === 0}
        >
          <StyledButtonTitle
            disabled={submitting || !userTeams || userTeams.length === 0}
          >
            Continue
          </StyledButtonTitle>
        </Button>
      </ButtonContainer>
    </FanPagesSelectorContainer>
  );
};

export default withAuthentication(FanPagesSelector);
