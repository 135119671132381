/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import queryString from "query-string";
import { withAuthentication } from "../../../../containers/Session";
import { withRouter } from "react-router-dom";
import {
  DashboardTitle,
  FlexContainer,
  DashboardFilter,
  SearchBar,
  DashboardButton,
  SpinLoader,
  MobileMarginContainer,
} from "../../../../assets/fansaves-ui";
import styled from "styled-components/macro";

import { DashboardStats, DashboardHeader } from "../../../../containers/UIKit";
import { isAdmin, isTeam } from "../../../../containers/Session";

import "../../../../scss/base/typography.scss";

import DashboardBusinessCard from "../../../../containers/DashboardBusinessCard";
import moment from "moment";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useTeamsStore from "../../../../stores/teamsStore";
import useSponsorsStore from "../../../../stores/sponsorsStore";

const StyledSponsorsList = styled(FlexContainer)`
  gap: 45px;
  margin: 50px 0;
  .sponsors-list-title {
    justify-content: flex-start;
  }
  .team-sponsor-list-header {
    margin: 0;
  }
  .business-search-bar {
    align-self: start;
  }
  .sponsors-list-container {
    position: relative;
    margin-bottom: 60px;
    .component-dashboard-filter {
      position: absolute;
      top: -30px;
      left: 0;
      z-index: 2;
    }
  }
`;

const SponsorsList = ({ firebase, match, authUser, history }) => {
  const teamId = match.params.team_id;
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const teams = useTeamsStore((state) => state.teams);
  const fetchTeams = useTeamsStore((state) => state.fetchTeams);
  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);

  const sponsorsFromStore = useSponsorsStore((state) => state.sponsors);
  const teamSponsorsFromStore = useSponsorsStore((state) => state.teamSponsors);
  const fetchSponsors = useSponsorsStore((state) => state.fetchSponsors);
  const fetchTeamSponsors = useSponsorsStore(
    (state) => state.fetchTeamSponsors
  );

  const [team, setTeam] = useState(null);
  const [sponsors, setSponsors] = useState(null);
  const [selectedSponsors, setSelectedSponsors] = useState(null);
  const [activeOffers, setActiveOffers] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if (!redemptionsFromEvents) {
      fetchRedemptions(firebase);
    }
  }, [fetchRedemptions, firebase]);

  useEffect(() => {
    if (isAdmin(authUser) && !teams) {
      fetchTeams(firebase);
    }
  }, [fetchTeams, firebase, authUser]);

  useEffect(() => {
    if (isAdmin(authUser) && !sponsorsFromStore) {
      fetchSponsors(firebase);
    }
  }, [fetchSponsors, firebase]);

  useEffect(() => {
    if (
      (isTeam(authUser) && !teamSponsorsFromStore) ||
      (teamSponsorsFromStore && !teamSponsorsFromStore[teamId])
    ) {
      fetchTeamSponsors(firebase, teamId);
    }
  }, [fetchTeamSponsors, firebase, teamId]);

  useEffect(() => {
    if (teamId && (!team || team.key !== teamId)) {
      fetchTeam(firebase, teamId);
    }
  }, [fetchTeam, firebase, teamId]);

  useEffect(() => {
    if (redemptionsFromEvents) {
      if (teamId) {
        setRedemptions(
          redemptionsFromEvents.filter(
            (redemption) => redemption.teamID === teamId
          )
        );
      } else {
        setRedemptions(redemptionsFromEvents);
      }
    }
  }, [redemptionsFromEvents]);

  useEffect(() => {
    if (isAdmin(authUser)) {
      if (sponsorsFromStore) {
        if (teamId) {
          setSponsors(
            sponsorsFromStore.filter(
              (sponsor) =>
                sponsor.teams && Object.keys(sponsor.teams).includes(teamId)
            )
          );
        } else {
          setSponsors(sponsorsFromStore);
        }
      }
    } else if (isTeam(authUser)) {
      if (teamSponsorsFromStore && teamSponsorsFromStore[teamId]) {
        setSponsors(teamSponsorsFromStore[teamId]);
      }
    }
  }, [sponsorsFromStore, teamSponsorsFromStore]);

  const getFilterAndQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const filter = search.filter ? search.filter : null;
    const query = search.query;

    if (filter) {
      setFilter(filter);
    } else {
      setFilter("Alphabetical");
    }
    if (query) {
      setSearchQuery(query);
    }
  };

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (filter) {
      params.filter = filter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };
  useEffect(() => {
    getFilterAndQueryFromSearch();
  }, []);

  useEffect(() => {
    setUrlQueryFromFilters();
  }, [filter, searchQuery]);

  let stats = [
    {
      label: "Total Businesses",
      value: sponsors
        ? sponsors.length > 0
          ? sponsors.length
          : 0
        : renderLoading(),
    },
    {
      label: "Archived Businesses",
      value: sponsors
        ? sponsors.filter((sponsor) => sponsor.archived).length
        : renderLoading(),
    },
    {
      label: "Active Offers",
      value: activeOffers
        ? activeOffers.length > 0
          ? activeOffers.length
          : 0
        : renderLoading(),
    },
    {
      label: "Total Redemptions",
      value: redemptions
        ? redemptions.filter(
            (redemption) => redemption.transactionType === "redemption"
          ).length
        : renderLoading(),
    },
    {
      label: "Total Contest Entries",
      value: redemptions
        ? redemptions.filter(
            (redemption) => redemption.transactionType === "contest"
          ).length
        : renderLoading(),
    },
  ];

  const filterSponsors = () => {
    let tempSponsors = [...sponsors];
    if (!filter) {
      setSelectedSponsors(tempSponsors);
    }

    switch (filter) {
      case "Alphabetical":
        tempSponsors.sort(
          (a, b) =>
            (a.name &&
              a.name.localeCompare(b.name, undefined, { numeric: true })) ||
            /^[0-9]/.test(a.name) - /^[0-9]/.test(b.name)
        );
        break;
      case "NewestToOldest":
        tempSponsors.sort((a, b) => {
          if (a.archived && !b.archived) return 1;
          else if (!a.archived && b.archived) return -1;
          else if (a.archived && b.archived) return 0;
          else
            return (
              moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
            );
        });
        break;
      case "OldestToNewest":
        tempSponsors.sort((a, b) => {
          if (a.archived && !b.archived) return 1;
          else if (!a.archived && b.archived) return -1;
          else if (a.archived && b.archived) return 0;
          else
            return (
              moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
            );
        });
        break;
      default:
        break;
    }
    setSelectedSponsors(tempSponsors);
  };

  const handleSearch = () => {
    let tempSponsors = [...sponsors];
    if (!searchQuery) {
      setSelectedSponsors(tempSponsors);
    }
    const filteredSponsors = tempSponsors.filter((sponsor) => {
      return sponsor.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setSelectedSponsors(filteredSponsors);
  };

  const filterRedemptionsAndCount = (business) => {
    if (!redemptions) {
      return 0;
    }
    const filteredRedemptions = redemptions.filter((redemption) => {
      return (
        redemption.sponsorID === business.key &&
        redemption.transactionType === "redemption"
      );
    });

    return filteredRedemptions ? filteredRedemptions.length : 0;
  };

  const filteredContestEntriesAndCount = (business) => {
    if (!redemptions) {
      return 0;
    }
    const filteredContestEntries = redemptions.filter((redemption) => {
      return (
        redemption.sponsorID === business.key &&
        redemption.transactionType === "contest"
      );
    });

    return filteredContestEntries ? filteredContestEntries.length : 0;
  };

  useEffect(() => {
    if (sponsors && filter) {
      filterSponsors();
    }
  }, [filter, sponsors]);

  useEffect(() => {
    if (sponsors && searchQuery) {
      handleSearch();
    }
  }, [searchQuery, sponsors]);

  const calculateActiveOffers = () => {
    let activeOffers = [];
    if (sponsors) {
      sponsors.forEach((sponsor) => {
        if (sponsor.offers && sponsor.offers.length > 0) {
          sponsor.offers.filter((offer) => {
            if (offer.active) {
              activeOffers.push(offer);
            }
          });
        }
      });
    }
    setActiveOffers(activeOffers);
  };

  useEffect(() => {
    if (sponsors) {
      calculateActiveOffers();
    }
  }, [sponsors]);

  const getTeamsForSponsor = (sponsorId) => {
    if (!teams) return null;
    const teamsForSponsor = teams.filter((team) => {
      if (team.sponsors) {
        return team.sponsors.includes(sponsorId);
      }
      return false;
    });

    return teamsForSponsor;
  };

  return (
    <StyledSponsorsList>
      <FlexContainer
        className="sponsors-list-title"
        direction="horizontal"
        justifyContent="space-between"
        responsive
      >
        <FlexContainer gap="30px" alignItems="flex-start" width="100%">
          {team && (
            <DashboardHeader
              logo={team.logo}
              name={team.name}
              email={team.email}
              profileUrl={team.website}
              hideEl
              className="team-sponsor-list-header"
            />
          )}
          <DashboardTitle
            title="Businesses"
            backButtonPath={`/portal/${
              match.params.team_id
                ? "teams/" + match.params.team_id + "/dashboard"
                : "dashboard"
            }`}
            className="sponsors-list-title"
            justifyContent="flex-start"
          />
          <DashboardButton
            title="Add a Business"
            icon="fa-solid:plus"
            size="medium"
            type="green"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portal/sponsors/create`);
            }}
            width="220px"
          />
        </FlexContainer>
        <MobileMarginContainer margin="10px">
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder="Search Businesses"
            className="business-search-bar"
          />
        </MobileMarginContainer>
      </FlexContainer>
      <DashboardStats stats={stats} />
      <FlexContainer
        direction="column"
        gap="20px"
        className="sponsors-list-container"
      >
        <DashboardFilter
          type="business"
          className="component-dashboard-filter inter-b2"
          onSelect={setFilter}
          initialFilterOption={filter}
        />

        {!sponsors && !selectedSponsors ? (
          <div className="no-businesses">
            <SpinLoader />
          </div>
        ) : selectedSponsors && selectedSponsors.length > 0 ? (
          selectedSponsors.map((sponsor, index) => (
            <DashboardBusinessCard
              key={sponsor.key + sponsor.name}
              business={sponsor}
              renderButtonsGroup
              redemptionsCount={filterRedemptionsAndCount(sponsor)}
              contestEntriesCount={filteredContestEntriesAndCount(sponsor)}
              showRedemptionsTitle
              teamsForLogo={getTeamsForSponsor(sponsor.key)}
            />
          ))
        ) : (
          <div className="no-businesses">No businesses found.</div>
        )}
      </FlexContainer>
    </StyledSponsorsList>
  );
};

export default withFirebase(withAuthentication(withRouter(SponsorsList)));
