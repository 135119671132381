/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { defaultLayoutRoutes } from "../../../routes_default_layout";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import { withEmbedMode } from "../../../containers/EmbedModeContext";
import logo from "../../../assets/img/brand/logo.png";
import LoginModal from "../../../containers/LoginModal";
import { Button, DefaultContainer } from "../../../containers/UIKit";
import DefaultLayoutFooter from "./DefaultLayoutFooter";
import PortableSearchBar from "../../../containers/PortableSearchBar";
import "./DefaultLayout.scss";
import TranslationDropdown from "../../../containers/TranslationDropdown";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import LoginForm from "../../../containers/LoginForm";
import queryString from "query-string";

const StyledLoginPopup = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1067;

  .component-login-form {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

class DefaultLayout extends React.Component {
  state = {
    showLoginModal: false,
    loginModalMode: "login",
    showMobileNavLinks: false,
    isMobile: false,
    onboarding: false,
    showLoginPopup: false,
  };

  handleLoginClick = (e) => {
    e.preventDefault();
    this.showLoginModal("login");
    this.setState({ showMobileNavLinks: false });
  };

  handleSignUpClick = (e) => {
    e.preventDefault();
    this.showLoginModal("signup");
  };

  handleSearch = (searchTerms) => {
    // TODO: Figure out how to do this with Redirect component rather than window.location.href
    //       Hard to do this at the layout level.
    if (searchTerms.trim() !== "") {
      window.location.href = `/organizations?searchTerms=${searchTerms}`;
    }
  };

  showLoginModal = (loginModalMode) => {
    this.setState({ loginModalMode }, () => {
      this.setState({ showLoginModal: true, showLoginPopup: false });
    });
  };

  hideLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  routeComponentControl = () => {
    return defaultLayoutRoutes.map((route, idx) => {
      return route.component ? (
        <Route
          key={idx}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
  };

  checkLocation = () => {
    const { pathname } = window.location;
    const parsedQuery = queryString.parse(window.location.search);
    const { embedMode } = parsedQuery;
    if (
      embedMode ||
      pathname === "/" ||
      pathname === "/how-it-works" ||
      pathname === "/unlock-exclusives" ||
      pathname === "/privacy-policy" ||
      pathname === "/terms-and-conditions" ||
      pathname.includes("/email-francais")
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleResize = () => {
    const { isMobile } = this.state;
    if (window.innerWidth < 890 && !isMobile) {
      this.setState({ isMobile: true });
    } else if (window.innerWidth >= 890 && isMobile) {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // check location and set to the state
    const {
      location: { pathname },
    } = this.props;

    if (pathname === "/onboarding") {
      this.setState({ onboarding: true });
    } else {
      this.setState({ onboarding: false });
    }

    const showPopup = localStorage.getItem("showPopup");
    if (showPopup) {
      setTimeout(() => {
        this.setState({ showLoginPopup: showPopup });
      }, 5000);
    }
  }

  componentDidUpdate(prevProps) {
    const loginPopup = document.getElementById("login-popup");
    if (prevProps.location !== this.props.location) {
      const {
        location: { pathname },
      } = this.props;
      if (
        pathname === "/" ||
        pathname === "/how-it-works" ||
        pathname === "/unlock-exclusives"
      ) {
        if (loginPopup) {
          loginPopup.style.display = "none";
        }
      } else {
        const showPopup = localStorage.getItem("showPopup");

        this.setState({ showLoginPopup: showPopup });
        if (showPopup && loginPopup) {
          window.scrollTo(0, 0);
          loginPopup.style.display = "block";
        }
      }

      if (pathname === "/onboarding") {
        this.setState({ onboarding: true });
      } else {
        this.setState({ onboarding: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.setState({ showLoginPopup: false });
    localStorage.removeItem("showPopup");
  }

  navLinks = () => {
    const { authUser } = this.props;
    const { isMobile } = this.state;

    return (
      <>
        <Link
          className="unlock-exclusive-link"
          to="/unlock-exclusives"
          onClick={this.handleNavLinkClick}
        >
          Unlock Special Offers
        </Link>
        <Link to="/how-it-works" onClick={this.handleNavLinkClick}>
          About
        </Link>

        {!isMobile ? (
          <div className="submenu-group">
            <Link to="#">Browse</Link>
            <div className="submenu deals-submenu">
              <Link to="/deals" onClick={this.handleNavLinkClick}>
                All Deals
              </Link>
              <Link to="/organizations" onClick={this.handleNavLinkClick}>
                FanPages
              </Link>
            </div>
          </div>
        ) : (
          <>
            <Link to="/deals" onClick={this.handleNavLinkClick}>
              Browse All Deals
            </Link>
            <Link to="/organizations" onClick={this.handleNavLinkClick}>
              Browse FanPages
            </Link>
          </>
        )}
        <Link to="/deals?contestDeals=true" onClick={this.handleNavLinkClick}>
          Contests
        </Link>
        {authUser && (
          <Link to="/profile/edit" onClick={this.handleNavLinkClick}>
            Profile
          </Link>
        )}
        <TranslationDropdown
          isMobile={isMobile}
          onNavLinkClick={this.handleNavLinkClick}
        />
        {!authUser ? (
          <>
            <Button
              className="green login-button"
              onClick={this.handleLoginClick}
            >
              Log In / Sign up
            </Button>
          </>
        ) : (
          <Link to="/logout" className="submenu">
            <span data-notranslate>
              <FormattedMessage id="app.signout" defaultMessage={"Sign out"} />
            </span>
          </Link>
        )}
      </>
    );
  };

  handleMobileNavToggleClick = () => {
    const { showMobileNavLinks } = this.state;
    this.setState({ showMobileNavLinks: !showMobileNavLinks });
  };

  handleNavLinkClick = () => {
    this.setState({ showMobileNavLinks: false });
  };

  render() {
    const { embedMode, authUser } = this.props;

    const {
      showLoginModal,
      loginModalMode,
      showMobileNavLinks,
      onboarding,
      showLoginPopup,
    } = this.state;

    return (
      <div className="component-default-layout">
        <div className="default-header">
          <DefaultContainer>
            <div className="logo-container">
              <Link to="/" target={embedMode ? "_blank" : "_self"}>
                <img src={logo} className="logo" alt="logo" />
              </Link>
            </div>
            <div className="nav-actions">
              <div className="nav-links">{!embedMode && this.navLinks()}</div>
              <div className="nav-search-container">
                {!embedMode && !onboarding && (
                  <PortableSearchBar
                    onSearch={this.handleSearch}
                    placeholder="Search for a Team (eg. Rockford IceHogs)"
                  />
                )}
              </div>
              <div className="mobile-nav-toggle-container">
                {!embedMode && (
                  <a
                    href="#"
                    className="mobile-nav-toggle"
                    onClick={this.handleMobileNavToggleClick}
                  >
                    <i className="fa fa-bars" id="mobile-nav-toggle" />
                  </a>
                )}
              </div>
            </div>
          </DefaultContainer>
        </div>
        {showMobileNavLinks && (
          <div className="mobile-nav-links">{this.navLinks()}</div>
        )}
        <div
          className={`default-content ${
            !showLoginModal &&
            showLoginPopup &&
            !authUser &&
            this.checkLocation()
              ? "login-popup-open"
              : ""
          }`}
        >
          {showLoginPopup && !authUser && this.checkLocation() && (
            <StyledLoginPopup id="login-popup">
              <LoginForm
                onSuccessfulLogin={this.hideLoginModal}
                signUpSuggestion
              />
            </StyledLoginPopup>
          )}
          <Switch>{this.routeComponentControl()}</Switch>
        </div>
        {!embedMode && <DefaultLayoutFooter />}
        {showLoginModal && (
          <LoginModal
            onClose={this.hideLoginModal}
            mode={loginModalMode}
            returnToCurrentPageAfterOnboarding={true}
          />
        )}
      </div>
    );
  }
}

export default withFirebase(withAuthentication(withEmbedMode(DefaultLayout)));
