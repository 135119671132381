import React from "react";
import { Link } from "react-router-dom";
import SpecialOverlay from "../SpecialOverlay";
import { withAuthentication } from "../Session";
import { withEmbedMode } from "../EmbedModeContext";
import OrganizationLink from "../OrganizationLink";
import "./DealListing.scss";
import { withRouter } from "react-router-dom";
import PinIcon from "../../assets/img/green-and-white-pin.png";
import ShareLinkAndMediaModal from "../../containers/ShareLinkAndMediaModal";
import { unlockedExclusives } from "../../utilities/user_helpers";
import { FlexContainer } from "../../assets/fansaves-ui";
import { useIntl, FormattedMessage } from "react-intl";

const DealListing = (props) => {
  const { deal, size, authUser, embedMode, showRedeemButton } = props;
  const { params } = props.match;
  const { organization_id: organizationId, deal_group_id: dealGroupId } =
    params;
  const { locale } = useIntl();

  const dealFromSearch = deal.objectID !== undefined;

  let url;
  let teamKeys;

  const isDealGroup = deal.brand !== undefined;

  if (dealFromSearch) {
    url = `/deals/${deal.objectID}`;

    if (isDealGroup) {
      url = `/deal_groups/${deal.objectID}`;
    }

    if (deal.teams) {
      teamKeys = deal.teams.map((team) => team.key);
    }
  } else {
    url = `/deals/${deal.key}`;

    if (isDealGroup) {
      url = `/deal_groups/${deal.key}`;
    }

    teamKeys = deal.teams;
  }
  let unlocked = false;
  if (teamKeys) {
    teamKeys.forEach((teamKey) => {
      if (authUser && unlockedExclusives(authUser, teamKey)) {
        unlocked = true;
      }
    });
  } else {
    console.warn(`Warning: Could not retrieve team data for ${deal.key}`);
  }

  const isEnteredContest = () => {
    let entered = false;
    if (authUser) {
      if (authUser.redeemed) {
        Object.keys(authUser.redeemed).forEach((key) => {
          if (deal.contestDeal && (key === deal.objectID || key === deal.key)) {
            entered = true;
          }
        });
      }
    }
    return entered;
  };

  const renderContestButtonText = () => {
    if (isEnteredContest()) {
      return "Entered";
    } else {
      return "Enter";
    }
  };

  const translateDescription = () => {
    const description = deal.description;
    const descriptionFr = deal.descriptionFr;
    if (descriptionFr) {
      return (
        <div className={`description ${size}`} data-notranslate>
          {locale === "fr" ? descriptionFr : description}
        </div>
      );
    } else {
      return <div className={`description ${size}`}>{deal.description}</div>;
    }
  };

  return (
    <Link
      to={url}
      target={embedMode ? "_blank" : "_self"}
      className={`component-deal-listing ${size}`}
    >
      {size === "feed" && (
        <div className="organization-business-header">
          <div className="business-info">
            {deal.sponsor && (
              <>
                <img
                  src={deal.sponsor.picture}
                  alt={`${deal.sponsor.name} logo`}
                />
                <div>
                  <div className="business-name" data-notranslate>
                    {deal.sponsor.name}
                  </div>
                  <div className="business-address" data-notranslate>
                    {deal.sponsor.address}
                  </div>
                </div>
              </>
            )}
            {isDealGroup && (
              <>
                <img src={deal.brand.logo} alt={`${deal.brand.name} logo`} />
                <div>
                  <div className="business-name" data-notranslate>
                    {deal.brand.name}
                  </div>
                  <div className="business-address">Multiple locations</div>
                </div>
              </>
            )}
          </div>
          <div className="organization-info">
            {deal.organizationInfo && (
              <OrganizationLink organization={deal.organizationInfo}>
                <img
                  src={deal.organizationInfo.logo}
                  alt={`${deal.organizationInfo.name} logo`}
                />
              </OrganizationLink>
            )}
          </div>
        </div>
      )}
      <div className="image-area">
        {(organizationId || dealGroupId) && deal.pinned && (
          <img src={PinIcon} className="pin-deal-icon" alt="Pinned Deal" />
        )}
        <img
          src={deal.picture}
          className="deal-picture"
          alt={deal.description}
        />
        <SpecialOverlay deal={deal} unlocked={unlocked} size={size} />
      </div>
      <div className={`details ${size === "large"}`}>
        {size !== "feed" && !deal.contestDeal && (
          <div className="sponsor-logo-container">
            {deal.sponsor && (
              <img
                src={deal.sponsor.picture}
                className="sponsor-picture"
                alt={deal.sponsor.name}
              />
            )}
            {isDealGroup && (
              <img
                src={deal.brand.logo}
                className="sponsor-picture"
                alt={deal.brand.name}
              />
            )}
          </div>
        )}
        {size !== "feed" && deal.contestDeal && (
          <div className="sponsor-logo-container">
            {deal.teams && deal.teams[0] && (
              <img
                src={deal.teams[0].logo}
                className="sponsor-picture"
                alt={deal.teams[0].name}
              />
            )}
          </div>
        )}
        {/* <div className={`writeup ${deal.contestDeal ? "contest-deal" : ""}`}> */}
        <div className="writeup">
          {translateDescription()}
          {deal.sponsor && size !== "feed" && !deal.contestDeal && (
            <>
              <div className="sponsor-name" data-notranslate>
                {deal.sponsor.name}
              </div>
              <div className="address" data-notranslate>
                {deal.sponsor.address}
              </div>
            </>
          )}
          {isDealGroup && size !== "feed" && !deal.contestDeal && (
            <>
              <div className="sponsor-name" data-notranslate>
                {deal.brand.name}
              </div>
              <div className="address">Multiple locations</div>
            </>
          )}
          {deal.contestDeal && deal.teams && deal.teams[0] && (
            <>
              <div className="sponsor-name" data-notranslate>
                {deal.teams[0].name}
              </div>
            </>
          )}
        </div>
        {(size === "feed" || showRedeemButton) && (
          <div className="redeem-container">
            <div
              className={`redeem-button ${
                isEnteredContest() ? "contest-deal-entered" : ""
              }`}
            >
              {deal.contestDeal ? (
                renderContestButtonText()
              ) : (
                <span data-notranslate>
                  <FormattedMessage
                    id="app.deal.redeem"
                    defaultMessage={"Redeem"}
                  />
                </span>
              )}
            </div>
            <ShareLinkAndMediaModal url={url} deal={deal} />
          </div>
        )}
      </div>
    </Link>
  );
};

export default withAuthentication(withEmbedMode(withRouter(DealListing)));
