/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import {
  withAuthentication,
  isTeam,
  isSponsor,
  isAdmin,
} from "../../../../containers/Session";
import { withRouter } from "react-router-dom";
import { DashboardHeader, DashboardStats } from "../../../../containers/UIKit";
import DashboardDealList from "../../../../containers/DashboardDealList";
import DashboardRedemptionsTable from "../../../../containers/DashboardRedemptionsTable";
import DashboardChartContainer from "../../../../containers/DashboardChartContainer";
import DashboardRedemptionAnalytics from "../../../../containers/DashboardRedemptionAnalytics";
import {
  getRedemptionChartData,
  getImpressionsChartData,
  mapUserDataforCharts,
} from "../../../../utilities/dashboard_helpers";

import {
  DashboardButton,
  FlexContainer,
  LineChart,
  SpinLoader,
} from "../../../../assets/fansaves-ui";

import "./PortalSponsorDashboard.scss";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useOffersStore from "../../../../stores/offersStore";
import useSponsorsStore from "../../../../stores/sponsorsStore";
import teamsStore from "../../../../stores/teamsStore";

const PortalSponsorDashbord = ({ firebase, history, match, authUser }) => {
  const sponsorID = match.params.sponsor_id;
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const fetchSponsorOffers = useOffersStore(
    (state) => state.fetchSponsorOffers
  );
  const fetchOffers = useOffersStore((state) => state.fetchOffers);
  const fetchTeamOffers = useOffersStore((state) => state.fetchTeamOffers);

  const sponsorOffers = useOffersStore((state) => state.sponsorOffers);
  const teamOffers = useOffersStore((state) => state.teamOffers);
  const offers = useOffersStore((state) => state.offersArray);
  const impressions = useOffersStore((state) => state.sponsorImpressions);
  const setSponsorImpressions = useOffersStore(
    (state) => state.setSponsorImpressions
  );

  const sponsorFromStore = useSponsorsStore((state) => state.sponsor);
  const fetchSponsor = useSponsorsStore((state) => state.fetchSponsor);

  const teamFromStore = teamsStore((state) => state.team);
  const fetchTeam = teamsStore((state) => state.fetchTeam);
  const [sponsor, setSponsor] = useState(sponsorFromStore);
  const [team, setTeam] = useState(null);

  const redemptions = useRedemptionsStore((state) => state.redemptions);
  const [deals, setDeals] = useState(null);
  const [redemptionsFromEvents, setRedemptionsFromEvents] = useState(null);
  const [contestEntries, setContestEntries] = useState(null);
  const [
    redemptionsWithoutContestEntries,
    setRedemptionsWithoutContestEntries,
  ] = useState(null);
  const [publishedDeals, setPublishedDeals] = useState(null);
  const [archivedDeals, setArchivedDeals] = useState(null);
  const [flashDeals, setFlashDeals] = useState(null);
  const [triggeredDeals, setTriggeredDeals] = useState(null);
  const [contestDeals, setContestDeals] = useState(null);
  const [genderChartData, setGenderChartData] = useState(null);
  const [ageChartData, setAgeChartData] = useState(null);
  const [impressionsChartData, setImpressionsChartData] = useState(null);
  const [redemptionsChartData, setRedemptionsChartData] = useState(null);
  const [locationChartData, setLocationChartData] = useState(null);
  const [selectedLocationChartData, setSelectedLocationChartData] =
    useState(null);
  const [selectedRedemtionFilter, setSelectedRedemtionFilter] =
    useState("7days");
  const [selectedImpressionFilter, setSelectedImpressionFilter] =
    useState("7days");
  const [impressionsFilterDaysAdjustment, setImpressionsFilterDaysAdjustment] =
    useState(0);
  const [redemptionsFilterDaysAdjustment, setRedemptionsFilterDaysAdjustment] =
    useState(0);
  const [heatMapData, setHeatMapData] = useState(null);

  useEffect(() => {
    if (sponsorFromStore && sponsorFromStore[sponsorID]) {
      setSponsor(sponsorFromStore[sponsorID]);
    }
  }, [sponsorFromStore, sponsorID]);

  useEffect(() => {
    const teamID = sponsor && sponsor.teams && Object.keys(sponsor.teams)[0];
    if (teamID && teamFromStore && teamFromStore[teamID]) {
      setTeam(teamFromStore[teamID]);
    }
  }, [teamFromStore, sponsor]);

  useEffect(() => {
    fetchRedemptions(firebase);
  }, [fetchRedemptions, firebase]);

  useEffect(() => {
    if (!sponsor || sponsor.key !== sponsorID) {
      fetchSponsor(firebase, sponsorID);
    }
  }, [fetchSponsor, firebase, sponsorID]);

  useEffect(() => {
    if (isAdmin(authUser) && !deals) {
      fetchOffers(firebase);
    } else if (isTeam(authUser) && !deals) {
      fetchTeamOffers(firebase, authUser.teamID);
    } else if (isSponsor(authUser) && !deals) {
      fetchSponsorOffers(firebase, sponsorID);
    }
  }, [firebase, sponsorID, deals]);

  useEffect(() => {
    if (isAdmin(authUser) && offers) {
      setDeals(
        offers.filter(
          (offer) => offer.sponsor && offer.sponsor.sponsorKey === sponsorID
        )
      );
    } else if (isTeam(authUser) && teamOffers) {
      setDeals(
        teamOffers.filter(
          (offer) => offer.sponsor && offer.sponsor.sponsorKey === sponsorID
        )
      );
    } else if (isSponsor(authUser) && sponsorOffers) {
      setDeals(sponsorOffers);
    }
  }, [offers, teamOffers, sponsorOffers, authUser, sponsorID]);

  useEffect(() => {
    if (redemptions) {
      const filteredRedemptions = redemptions.filter(
        (redemption) => redemption.sponsorID === sponsorID
      );
      setRedemptionsFromEvents(filteredRedemptions);

      const filteredContestEntries = filteredRedemptions.filter(
        (redemption) => redemption.transactionType === "contest"
      );
      setContestEntries(filteredContestEntries);

      const filteredRedemptionsWithoutContestEntries =
        filteredRedemptions.filter(
          (redemption) => redemption.transactionType === "redemption"
        );
      setRedemptionsWithoutContestEntries(
        filteredRedemptionsWithoutContestEntries
      );
    }
  }, [redemptions, sponsorID]);

  useEffect(() => {
    const teamID = sponsor && sponsor.teams && Object.keys(sponsor.teams)[0];
    if (
      (teamID && !teamFromStore) ||
      (teamFromStore && !teamFromStore[teamID])
    ) {
      fetchTeam(firebase, teamID);
    }
  }, [sponsor, fetchTeam, firebase]);

  const getImpressions = () => {
    const impressions = [];
    const sponsorOffersMap = deals.reduce((map, offer) => {
      map[offer.key] = offer;
      return map;
    }, {});

    if (sponsorOffersMap && sponsor.offers && sponsor.offers.length > 0) {
      sponsor.offers.forEach((offerSpec) => {
        const offer = sponsorOffersMap[offerSpec.key];
        if (offer && offer.impressions) {
          impressions.push(Object.values(offer.impressions));
        }
      });
    }

    const flattenedImpressions = [].concat.apply([], impressions);

    setSponsorImpressions(flattenedImpressions);
  };

  useEffect(() => {
    if (deals && sponsor) {
      getImpressions();
    }
  }, [deals, sponsor]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        margin="30px auto"
        height="100px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };

  let stats = [
    {
      label: "Redemptions",
      value: redemptionsWithoutContestEntries
        ? redemptionsWithoutContestEntries.length > 0
          ? redemptionsWithoutContestEntries.length
          : 0
        : renderLoading(),
    },
    {
      label: "Contest Entries",
      value: contestEntries
        ? contestEntries.length > 0
          ? contestEntries.length
          : 0
        : renderLoading(),
    },
    {
      label: "Deals",
      value: deals ? (deals.length > 0 ? deals.length : 0) : renderLoading(),
    },
    {
      label: "Archived Deals",
      value: archivedDeals
        ? archivedDeals.length > 0
          ? archivedDeals.length
          : 0
        : renderLoading(),
    },
    {
      label: "Flash Deals",
      value: flashDeals
        ? flashDeals.length > 0
          ? flashDeals.length
          : 0
        : renderLoading(),
    },
  ];

  const archivedOffers = () => {
    let offers = [];
    if (deals) {
      offers = deals
        .filter((deal) => !deal.published)
        .sort((a, b) => (a.archivedAt > b.archivedAt ? -1 : 1));
    }
    return offers;
  };

  const flashOffers = () => {
    let offers = [];
    if (deals) {
      offers = deals
        .filter((deal) => deal.published && deal.flashDeal)
        .sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    }
    return offers;
  };

  const triggeredOffers = () => {
    let offers = [];
    if (deals) {
      offers = deals
        .filter((deal) => deal.published && deal.triggered)
        .sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    }

    return offers;
  };

  const publishedOffers = () => {
    let offers = [];
    if (deals) {
      offers = deals
        .filter((deal) => deal.published && !deal.triggered)
        .sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    }
    return offers;
  };

  const contestOffers = () => {
    let offers = [];
    if (deals) {
      offers = deals
        .filter((deal) => deal.published && deal.contestDeal)
        .sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    }
    return offers;
  };

  const updateRedemptionsChartData = () => {
    if (redemptionsFromEvents) {
      const redemptionsData = getRedemptionChartData(
        redemptionsFromEvents,
        selectedRedemtionFilter,
        redemptionsFilterDaysAdjustment
      );
      setRedemptionsChartData(redemptionsData);
    }
  };

  const updateImpressionsChartData = () => {
    if (impressions) {
      const impressionsData = getImpressionsChartData(
        impressions,
        selectedImpressionFilter,
        impressionsFilterDaysAdjustment
      );
      setImpressionsChartData(impressionsData);
    }
  };

  const mapData = () => {
    setPublishedDeals(publishedOffers());
    setArchivedDeals(archivedOffers());
    setFlashDeals(flashOffers());
    setTriggeredDeals(triggeredOffers());
    setContestDeals(contestOffers());
  };

  const mapUserData = () => {
    if (redemptionsFromEvents) {
      const { genderData, ageData, userLocationMap, userStateMap } =
        mapUserDataforCharts(redemptionsFromEvents);

      let selectedUserLocationMap = {};

      setGenderChartData(genderData);
      setAgeChartData(ageData);

      if (Object.keys(userLocationMap).length > 5) {
        selectedUserLocationMap = Object.keys(userLocationMap)
          .slice(0, 6)
          .reduce((obj, key) => {
            obj[key] = userLocationMap[key];
            return obj;
          }, {});
      }

      setHeatMapData(userStateMap);
      setSelectedLocationChartData(selectedUserLocationMap);
      setLocationChartData(userLocationMap);
    }
  };

  useEffect(() => {
    if (deals) {
      mapData();
    }
  }, [deals]);

  useEffect(() => {
    if (redemptionsFromEvents) {
      updateRedemptionsChartData();
    }
  }, [
    redemptionsFromEvents,
    selectedRedemtionFilter,
    redemptionsFilterDaysAdjustment,
  ]);

  useEffect(() => {
    if (redemptionsFromEvents) {
      mapUserData();
    }
  }, [redemptionsFromEvents]);

  useEffect(() => {
    if (impressions) {
      updateImpressionsChartData();
    }
  }, [impressions, selectedImpressionFilter, impressionsFilterDaysAdjustment]);

  useEffect(() => {
    if (selectedRedemtionFilter) {
      setRedemptionsFilterDaysAdjustment(0);
    }
    if (selectedImpressionFilter) {
      setImpressionsFilterDaysAdjustment(0);
    }
  }, [selectedRedemtionFilter, selectedImpressionFilter]);

  useEffect(() => {
    if (redemptionsFilterDaysAdjustment > 0) {
      setSelectedRedemtionFilter("7days");
    }
    if (impressionsFilterDaysAdjustment > 0) {
      setSelectedImpressionFilter("7days");
    }
  }, [redemptionsFilterDaysAdjustment, impressionsFilterDaysAdjustment]);

  if (!sponsor)
    return (
      <FlexContainer
        className="component-portal-sponsor-dashboard animated fadeIn spin-loader-container"
        direction="horizontal"
        justifyContent="center"
        alignItems="center"
        margin="30px auto"
      >
        <SpinLoader />
      </FlexContainer>
    );

  return (
    <div
      className="component-portal-sponsor-dashboard animated fadeIn"
      id="main"
    >
      <FlexContainer
        direction="horizontal"
        justifyContent="space-between"
        responsive
        mobileDirection="column-reverse"
      >
        {sponsor && (
          <DashboardHeader
            logo={sponsor.picture}
            name={sponsor.name}
            email={sponsor.displayEmail}
            description={sponsor.description}
            profileUrl={sponsor.url}
            hideEl
          />
        )}
        {team && (
          <DashboardHeader
            logo={team.logo}
            name={team.name}
            email={team.displayEmail}
            profileUrl={team.website}
            hideEl
            secondaryHeader
          />
        )}
      </FlexContainer>
      <DashboardStats stats={stats} />
      {sponsor && (
        <FlexContainer
          className="action-buttons"
          direction="horizontal"
          justifyContent="space-between"
          gap="20px"
          responsive
        >
          <DashboardButton
            title="Add Deal"
            type="green"
            icon="fa-solid:plus"
            onClick={(e) => {
              e.preventDefault();
              if (isTeam(authUser) || isSponsor(authUser)) {
                window.open(
                  "https://form.jotform.com/202084089266256",
                  "_blank",
                  "noopener,noreferrer"
                );
              } else {
                history.push(`/portal/sponsors/${sponsor.key}/deals/create`);
              }
            }}
          />
          <DashboardButton
            title="Payments"
            icon="fa-solid:dollar-sign"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portal/sponsors/${sponsor.key}/payments-setup`);
            }}
          />
          <DashboardButton
            title="Purchases"
            icon="fa-solid:receipt"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portal/sponsors/${sponsor.key}/purchases`);
            }}
          />
          {/* <DashboardButton
              title="Assets"
              icon="bxs:folder"
              href={`/portal/sponsors/${sponsor.key}/assets`}
            /> */}
        </FlexContainer>
      )}
      {!triggeredDeals && !publishedDeals && !flashDeals && !archivedDeals ? (
        renderLoadingContainer()
      ) : (
        <>
          {triggeredDeals && (
            <DashboardDealList
              deals={triggeredDeals}
              className="triggered-deals"
              circledTitle
              dealTitle="Triggered Deals"
              hideTitle
            />
          )}
          {publishedDeals && (
            <DashboardDealList
              deals={publishedDeals}
              className="deals"
              dealTitle="All Deals"
              circledTitle
              viewAll
              hideTitle
            />
          )}
          {flashDeals && (
            <DashboardDealList
              deals={flashDeals}
              className="flash-deals"
              circledTitle
              dealTitle="Flash Deals"
              hideTitle
            />
          )}
          {contestDeals && (
            <DashboardDealList
              deals={contestDeals}
              className="contest-deals"
              circledTitle
              dealTitle="Contests"
              hideTitle
            />
          )}
          {archivedDeals && (
            <DashboardDealList
              deals={archivedDeals}
              className="archived-deals"
              dealTitle="Archived Deals"
              viewAll
              hideTitle
            />
          )}
        </>
      )}
      <DashboardChartContainer
        title="Impressions"
        onSelectFilter={setSelectedImpressionFilter}
        onFilterDaysAdjustment={setImpressionsFilterDaysAdjustment}
        filterDaysAdjustment={impressionsFilterDaysAdjustment}
        renderChart={() => {
          if (impressionsChartData) {
            return (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={selectedImpressionFilter}
                filterDaysAdjustment={impressionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      {redemptionsFromEvents ? (
        <DashboardRedemptionsTable
          title="Redemptions"
          redemptions={redemptionsFromEvents}
          limit={5}
          showAllClick={() => {
            history.push(`/portal/sponsors/${sponsor.key}/redemptions`);
          }}
        />
      ) : (
        renderLoadingContainer()
      )}
      <DashboardChartContainer
        title="Redemptions Graph"
        onSelectFilter={setSelectedRedemtionFilter}
        onFilterDaysAdjustment={setRedemptionsFilterDaysAdjustment}
        filterDaysAdjustment={redemptionsFilterDaysAdjustment}
        renderChart={() => {
          if (redemptionsChartData) {
            return (
              <LineChart
                dataSet={redemptionsChartData}
                showLegend
                filter={selectedRedemtionFilter}
                filterDaysAdjustment={redemptionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      {redemptionsFromEvents ? (
        <DashboardRedemptionAnalytics
          redemptions={redemptionsFromEvents}
          genderChartData={genderChartData}
          ageChartData={ageChartData}
          locationChartData={locationChartData}
          selectedLocationChartData={selectedLocationChartData}
          heatMapData={heatMapData}
        />
      ) : (
        renderLoadingContainer()
      )}
    </div>
  );
};

export default withAuthentication(
  withFirebase(withRouter(PortalSponsorDashbord))
);
