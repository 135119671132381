import React from "react";
import { withFirebase } from "../../../containers/Firebase";
import { withRouter } from "react-router-dom";
import { DefaultContainer } from "../../../containers/UIKit";
import queryString from "query-string";
import { InstantSearch, Hits, PoweredBy } from "react-instantsearch-dom";
import {
  searchClient,
  SearchBox,
  RefinementList,
  TeamsResultsWithEmptyState,
} from "../../../containers/Algolia";
import OrganizationListingHitWrapper from "../../../containers/OrganizationsList/OrganizationListingHitWrapper";
import { orderBy } from "lodash";

import "./OrganizationBrowser.scss";

class OrganizationBrowser extends React.Component {
  constructor(props) {
    super(props);

    const queryStringValues = queryString.parse(props.location.search);

    this.state = {
      searchTerms: queryStringValues.searchTerms || "",
      defaultCategory: queryStringValues.category,
      defaultSubcategory: queryStringValues.subcategory,
      showFiltersInMobile: false,
    };
  }

  toggleMobileFilters = (e) => {
    e.preventDefault();
    const { showFiltersInMobile } = this.state;
    this.setState({ showFiltersInMobile: !showFiltersInMobile });
  };

  render() {
    const {
      searchTerms,
      showFiltersInMobile,
      defaultCategory,
      defaultSubcategory,
    } = this.state;

    const defaultCategories = defaultCategory ? [defaultCategory] : [];
    const defaultSubcategories = defaultSubcategory ? [defaultSubcategory] : [];

    return (
      <div className="organization-browser-component">
        <DefaultContainer>
          <InstantSearch searchClient={searchClient} indexName="teams">
            <div className="search-bar-container">
              <SearchBox
                defaultRefinement={searchTerms}
                placeholder="Search FanPages..."
              />
            </div>

            <div className="search-results">
              <a
                onClick={this.toggleMobileFilters}
                className="toggle-mobile-filters"
              >
                {showFiltersInMobile ? "Hide Filters" : "Show Filters"}
              </a>
              <div
                className={`sort-and-filters ${
                  showFiltersInMobile ? "show-mobile" : "hide-mobile"
                }`}
              >
                <div className="section">
                  <h2>Filters</h2>

                  <div className="filter-section">
                    <h3>Category</h3>
                    <div>
                      <RefinementList
                        attribute="categories"
                        defaultRefinement={defaultCategories}
                        transformItems={(items) =>
                          orderBy(items, "label", "asc")
                        }
                      />
                    </div>
                  </div>

                  <div className="filter-section">
                    <h3>Sub Category</h3>
                    <div>
                      <RefinementList
                        attribute="subcategories"
                        defaultRefinement={defaultSubcategories}
                        transformItems={(items) =>
                          orderBy(items, "label", "asc")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="results">
                Results
                <TeamsResultsWithEmptyState message="We're sorry, but there were no results for your search.">
                  <Hits hitComponent={OrganizationListingHitWrapper} />
                </TeamsResultsWithEmptyState>
                <div className="algolia-powered-by-container">
                  <PoweredBy />
                </div>
              </div>
            </div>
          </InstantSearch>
        </DefaultContainer>
      </div>
    );
  }
}

export default withRouter(withFirebase(OrganizationBrowser));
