import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import { withFirebase } from '../../../containers/Firebase';
import PortableSearchBar from '../../../containers/PortableSearchBar';
import CheckOnboarding from '../../../containers/CheckOnboarding';
import FeaturedTeams from '../../../containers/FeaturedTeams';
import Feed from '../../../containers/Feed';
import { withAuthentication } from '../../../containers/Session';
import LoginModal from '../../../containers/LoginModal';
import { Button, DefaultContainer } from '../../../containers/UIKit';

import heroBg from '../../../assets/img/FanSaves-Banner-2022.jpg';

import searchBg from '../../../assets/img/FanSaves-Banner-Cheering.jpg';

import categorySports from '../../../assets/img/Category-Sports.jpg';
import categoryCommunities from '../../../assets/img/Category-Communities.jpg';
import categoryMediaInfluencers from '../../../assets/img/Category-Media-Influencers.jpg';
import categoryUniversities from '../../../assets/img/Category-Universities.jpg';
import categoryEvents from '../../../assets/img/Category-Events.jpg';

import rodneyWilson from '../../../assets/img/testimonials/Rodney-Wilson.png';
import kenOda from '../../../assets/img/testimonials/Ken-Oda.png';
import nataliePrychitko from '../../../assets/img/testimonials/Natalie-Prychitko.png';

import betakitLogo from '../../../assets/img/media-logos/betakit-logo.png';
import forbesLogo from '../../../assets/img/media-logos/forbes-logo.png';
import hypeLogo from '../../../assets/img/media-logos/hype-logo.png';
import sporttechxLogo from '../../../assets/img/media-logos/sporttechx-logo.png';
import tampabaywaveLogo from '../../../assets/img/media-logos/tampabaywave-logo.png';
import spotlightLogo from '../../../assets/img/media-logos/spotlight-logo.png';

import howToExplainerVideo from '../../../assets/video/FanSaves-How-To-Explainer.mp4';

import { Arrow, CircleHeart, Coins, StarMedal } from '../../../containers/UIKit/Icons';
import { CategoryTile, TestimonialSlide } from '../../../containers/UIKit';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Home.scss';

function Home({ authUser}) {

  const [searchTerms, setSearchTerms] = useState('');
  const [searched, setSearched] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [activeHowItWorksStepIndex, setActiveHowItWorksStepIndex] = useState(0);

  const testimonials = [
    {
      quote: "I love the FanSaves platform! It's awesome to be able to follow my favorite pages and teams then save money from brands I know support them. Even when the game is over, I have an easy way to see which businesses want to offer me a discount for being a loyal fan.",
      name: 'Rodney Wilson',
      role: 'FanSaves User',
      imageSrc: rodneyWilson,
    },
    {
      quote: "The Minot Minotauros organization is a big fan of FanSaves! We have seen the value of the platform first hand: The customer service is awesome and our fans have loved saving money around the Magic City while supporting our sponsors!",
      name: 'Ken Oda',
      role: 'Director of Operations, Minot Minotauros',
      imageSrc: kenOda,
    },
    {
      quote: "FanSaves has been the perfect way to digitalize our Chamber's Member-to-Member Discount Program and activate our members in a new and engaging way. We are beyond happy with our FanSaves experience so far!",
      name: 'Natalie Prychitko',
      role: 'CEO, Whitby Chamber of Commerce',
      imageSrc: nataliePrychitko,
    },
  ]

  function handleSearch(terms) {
    setSearchTerms(terms);
  }

  function handleSignupClick(e) {
    e.preventDefault();
    setShowLoginModal(true);
  }

  function hideLoginModal() {
    setShowLoginModal(false);
  }

  function queueChangeStep() {
    setTimeout(() => {
      const newStepIndex = (activeHowItWorksStepIndex + 1) % 3
      setActiveHowItWorksStepIndex(newStepIndex);
    }, 2000);
  }

  useEffect(() => {
    if(searchTerms.trim() !== '') {
      setSearched(true);
    }
  }, [searchTerms]);

  useEffect(queueChangeStep, []);
  useEffect(queueChangeStep, [activeHowItWorksStepIndex]);
  
  
  return(
    !searched ?
      <CheckOnboarding>
        <div className='component-home'>
          {
            authUser ?
              <DefaultContainer>
                <Feed />
              </DefaultContainer>
            :
              <>
                <div className='hero-banner'>
                  <img className='hero-banner-bg' src={heroBg} />
                  <div className='hero-banner-content'>
                    <DefaultContainer>
                      <div className='slogan-and-call-to-action'>
                        <div className='slogan-box'>
                          <div className='slogan emphasize'>Save money just for being a fan!</div>
                          <div className='subslogan emphasize'>
                            Follow your favorite FanPages to browse and redeem deals from their affiliated brands.
                          </div>
                        </div>
                        <div className='call-to-action'>
                          <Button className='green' onClick={handleSignupClick}>Sign Up for Free</Button>
                        </div>
                      </div>
                    </DefaultContainer>
                  </div>
                </div>
                <div className='home-page-section categories-section'>
                  <DefaultContainer>
                    <div className='pre-header-caption'>Everybody's a fan of something.</div>
                    <h2>What are you a fan of?</h2>
                    <div className='tiles'>
                      <CategoryTile
                        title="Sports Teams"
                        imageSrc={categorySports}
                        size="half"
                        subcategories={["Hockey", "Soccer", "Baseball", "Basketball", "Football", "Ultimate Frisbee"]}
                      />
                      <CategoryTile
                        title="Communities"
                        imageSrc={categoryCommunities}
                        size="half"
                        subcategories={["Chambers of Commerce", "BIAs", "Tourism"]}
                      />
                    </div>
                    <div className='tiles'>
                      <CategoryTile
                        title="Media & Influencers"
                        imageSrc={categoryMediaInfluencers}
                        size="third"
                        subcategories={["Influencers", "Magazines", "Athletes"]}
                      />
                      <CategoryTile
                        title="Universities & Colleges"
                        imageSrc={categoryUniversities}
                        size="third"
                        subcategories={["College & University Athletics", "Alumni"]}
                      />
                      <CategoryTile
                        title="Events"
                        imageSrc={categoryEvents}
                        size="third"
                        subcategories={["Coming Soon"]}
                      />
                    </div>
                    <div className='button-container'>
                      <Button className='blue large-padding' routerLink to='/organizations'>View All Fan Pages <Arrow /></Button>
                    </div>
                  </DefaultContainer>
                </div>
                <div className='home-page-section how-it-works-section'>
                  <DefaultContainer>
                    <div className='video-container'>
                      <video loop autoPlay muted>
                        <source src={howToExplainerVideo} />
                      </video>
                    </div>
                    <div className='text-container'>
                      <h2>How it Works</h2>
                      <div className='steps'>
                        <div className={`step ${activeHowItWorksStepIndex === 0 ? 'active' : ''}`}>
                          <div className='step-description'>
                            1. Follow your favorite FanPages.
                          </div>
                          <div className='step-details'>
                            Follow Fan Pages of teams, communities, influencers and more.
                          </div>
                        </div>
                        <div className={`step ${activeHowItWorksStepIndex === 1 ? 'active' : ''}`}>
                          <div className='step-description'>
                            2. Browse deals from affiliated brands.
                          </div>
                          <div className='step-details'>
                            Scroll through hundreds of deals from affiliated brands, sponsors and partners.
                          </div>
                        </div>
                        <div className={`step ${activeHowItWorksStepIndex === 2 ? 'active' : ''}`}>
                          <div className='step-description'>
                            3. Redeem deals just for being a fan.
                          </div>
                          <div className='step-details'>
                            No catch. No fees. No strings attached.
                          </div>
                        </div>
                      </div>
                    </div>
                  </DefaultContainer>
                </div>
                <div className='home-page-section featured-fan-pages-section'>
                  <DefaultContainer>
                    <h2>Featured FanPages</h2>
                    <FeaturedTeams />
                  </DefaultContainer>
                </div>
                <div className='home-page-section fan-of-fansaves-section'>
                  <DefaultContainer>
                    <h2>Fans of FanSaves</h2>
                    <div className='fan-of-fansaves-tiles'>
                      <div className='tile'>
                        <StarMedal />
                        <div className='write-up'>
                          FANS win by following their favorite FanPages and supporting affiliated brands by redeeming great
                          deals that they access free, just for being a fan
                        </div>
                      </div>
                      <div className='tile'>
                        <CircleHeart />
                        <div className='write-up'>
                          ORGANIZATIONS & INFLUENCERS win by showcasing the brands they love and support,
                          while tracking ROI and customer analytics
                        </div>
                      </div>
                      <div className='tile'>
                        <Coins />
                        <div className='write-up'>
                          BRANDS win by attracting support from new and loyal customers who are fans of the organizations and
                          influencers they are aligned with
                        </div>
                      </div>
                    </div>
                  </DefaultContainer>
                </div>
                <div className='home-page-section testimonials-section'>
                  <Carousel showThumbs={false} showStatus={false} showArrows={false} autoPlay infiniteLoop interval={5000}>
                    {testimonials.map((testimonial, index) => (<TestimonialSlide key={index} testimonial={testimonial} />))}
                  </Carousel>
                </div>
                <div className='home-page-section media-logos-section'>
                  <DefaultContainer>
                    <div className=''>Featured In:</div>
                    <a href='https://www.forbes.com/sites/melissahouston/2021/09/15/how-this-woman-grew-her-sports-tech-startup-successfully-in-a-male-dominated-industry/?sh=480701972bac' target='_blank'><img src={forbesLogo} /></a>
                    <a href='https://about.fansaves.com/media' target='_blank'><img src={sporttechxLogo} /></a>
                    <a href='https://about.fansaves.com/media' target='_blank'><img src={betakitLogo} /></a>
                    <a href='https://about.fansaves.com/media' target='_blank'><img src={spotlightLogo} /></a>
                    <a href='https://about.fansaves.com/media' target='_blank'><img src={hypeLogo} /></a>
                    <a href='https://about.fansaves.com/media' target='_blank'><img src={tampabaywaveLogo} /></a>
                  </DefaultContainer>
                </div>
                <div className='home-page-section search-bar-section'>
                  <img src={searchBg} />
                  <DefaultContainer>
                    <div className='search-bar-box'>
                      <div className='search-bar-caption'>Ready to redeem deals just for being a fan?</div>
                      <PortableSearchBar onSearch={handleSearch} placeholder='Search for a FanPage (eg: Atlanta Gladiators)' />
                    </div>
                  </DefaultContainer>
                </div>
              </>
          }

          {
            showLoginModal &&
            <LoginModal onClose={hideLoginModal} mode='signup' />
          }
        </div>
      </CheckOnboarding>
    :
      <Redirect push to={`/organizations?searchTerms=${searchTerms}`} />
  );
}

export default withFirebase(withAuthentication(Home));