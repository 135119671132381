import React, { Component } from "react";
import { withFirebase } from "../../../../../containers/Firebase";
import { validateDeal } from "../index";
import SponsorDealForm, {
  setupImageUploadPromises,
  setupGetDownloadUrlPromises,
} from "../Form";

import {
  dbLoadSponsor,
  dbLoadSponsorTeam,
  dbLoadOffer,
} from "../../../../../containers/DatabaseHelpers";

import { withRouter } from "react-router-dom";
import { withAuthentication } from "../../../../../containers/Session";
import withOffersStore from "../../../../../stores/withOffersStore";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSponsor: true,
      loadingSponsorTeam: true,
      loadingDeal: true,
    };
  }

  componentDidMount() {
    this.setState(
      {
        sponsorID: this.props.match.params.sponsor_id,
        dealID: this.props.match.params.deal_id,
      },
      () => {
        this.loadSponsor();
        this.loadSponsorTeam();
        this.loadDeal();
      }
    );
  }

  loadSponsor = () => {
    const { firebase } = this.props;
    const { sponsorID } = this.state;

    dbLoadSponsor(firebase, sponsorID, (sponsor) => {
      this.setState({ sponsor, loadingSponsor: false });
    });
  };

  loadSponsorTeam = () => {
    const { firebase } = this.props;
    const { sponsorID } = this.state;

    dbLoadSponsorTeam(firebase, sponsorID, (sponsorTeam) => {
      this.setState({ sponsorTeam, loadingSponsorTeam: false });
    });
  };

  loadDeal = () => {
    const { firebase } = this.props;
    const { dealID } = this.state;

    dbLoadOffer(firebase, dealID, (deal) => {
      this.setState({ deal, loadingDeal: false });
    });
  };

  handleSubmit = (deal) => {
    const { sponsor, sponsorTeam } = this.state;
    if (!validateDeal(deal, sponsor, sponsorTeam)) {
      return;
    }

    this.uploadImagesAndPushOfferData(deal);
  };

  uploadImagesAndPushOfferData = (deal) => {
    const { dealID } = this.state;

    const { firebase } = this.props;

    const imageUploadPromises = setupImageUploadPromises(
      firebase,
      dealID,
      deal
    );

    Promise.all(imageUploadPromises)
      .then((snapshots) => {
        const getDownloadUrlPromises = setupGetDownloadUrlPromises(snapshots);

        return Promise.all(getDownloadUrlPromises);
      })
      .then((urls) => {
        this.pushOfferData(deal, {
          pictureUrl: urls[0],
          redeemImageUrl: urls[1],
        });
      });
  };

  pushOfferData = (deal, imageUrls) => {
    const { dealID } = this.state;

    const { firebase } = this.props;
    const { sponsorID, sponsorTeam } = this.state;

    const ref = firebase.database.ref("offer/" + dealID);

    const {
      active,
      published,
      delayDay,
      delayHour,
      delayWeek,
      delayMonth,
      description,
      descriptionFr,
      terms,
      exclusive,
      dealType,
      website,
      onlineCode,
      flashDeal,
      contestDeal,
      hiddenDeal,
      price,
      originalValue,
      totalAvailable,
      expiresAt,
      keywords,
      currency,
      triggered,
      triggerDurationHours,
      redemptionsLimit,
      notTriggeredMessage,
      accessLevels,
    } = deal;

    var hours = parseInt(delayDay * 24) + parseInt(delayHour);
    var interval = {
      week: delayWeek,
      month: delayMonth,
      hour: hours,
    };

    const updateObject = {
      active,
      published,
      description,
      descriptionFr,
      terms,
      teams: [sponsorTeam.key],
      interval,
      exclusive,
      dealType,
      website,
      onlineCode,
      flashDeal,
      contestDeal,
      hiddenDeal,
      price,
      originalValue,
      totalAvailable,
      expiresAt,
      keywords,
      currency,
      triggered,
      accessLevels,
    };

    if (triggered) {
      updateObject.triggerDurationHours = triggerDurationHours;
      updateObject.redemptionsLimit = redemptionsLimit;
      updateObject.notTriggeredMessage = notTriggeredMessage;
    }

    if (imageUrls.pictureUrl) {
      updateObject.picture = imageUrls.pictureUrl;
    }

    if (imageUrls.redeemImageUrl) {
      updateObject.redeemImage = imageUrls.redeemImageUrl;
    }

    ref
      .update(updateObject)
      .then(() => {
        const sponsorRef = this.props.firebase.database.ref(
          "sponsor/" + sponsorID
        );
        sponsorRef.once("value").then((dataSnapshot) => {
          const offerList = dataSnapshot.val().offers
            ? dataSnapshot.val().offers
            : [];

          let indexOfOffer;
          offerList.forEach((offerListing, index) => {
            if (offerListing.key === ref.key) {
              indexOfOffer = index;
            }
          });

          if (indexOfOffer) {
            offerList[indexOfOffer].triggered = triggered;
            offerList[indexOfOffer].hiddenDeal = hiddenDeal;
            offerList[indexOfOffer].accessLevels = accessLevels;
          }
          sponsorRef
            .child("offers")
            .set(offerList)
            .then(() => {
              const {
                authUser,
                history,
                fetchOffersByRole,
                firebase,
                location,
              } = this.props;
              const { sponsorID } = this.state;

              fetchOffersByRole(
                firebase,
                authUser.role,
                authUser.teamID,
                sponsorID || authUser.sponsorID
              );
              if (
                !history ||
                !history.location ||
                !history.location.state ||
                !history.location.state.from
              ) {
                history.push(`/portal/sponsors/${sponsorID}/dashboard`);
              } else {
                if (history.location.state.from === location.pathname) {
                  history.push(`/portal/sponsors/${sponsorID}/dashboard`);
                } else {
                  history.push(history.location.state.from);
                }
              }
            });
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  render() {
    const { deal, loadingSponsor, loadingSponsorTeam, loadingDeal } =
      this.state;

    return loadingSponsor || loadingSponsorTeam || loadingDeal ? (
      <div>Loading...</div>
    ) : (
      <div className="animated fadeIn" id="main">
        <SponsorDealForm onSubmit={this.handleSubmit} existingDeal={deal} />
      </div>
    );
  }
}

export default withAuthentication(
  withFirebase(withRouter(withOffersStore(Edit)))
);
