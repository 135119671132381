import { create } from "zustand";
import { fetchRedemptions } from "../utilities/admin_helpers";

const useRedemptionsStore = create((set, get) => ({
  redemptions: null,
  contestEntries: null,
  redemptionsWithoutContestEntries: null,
  isLoading: false,
  error: null,
  isFetching: false,
  isLoadingDealRedemptions: false,
  errorDealRedemptions: null,
  dealRedemptions: null,

  fetchRedemptions: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await fetchRedemptions(firebase);
      const redemptionsWithoutContestEntries = data.filter(
        (redemption) => redemption.transactionType === "redemption"
      );
      const contestEntries = data.filter(
        (redemption) => redemption.transactionType === "contest"
      );

      set({
        redemptions: data,
        contestEntries,
        redemptionsWithoutContestEntries,
      });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchDealRedemptions: async (firebase, dealId, sponsorId, teamId) => {
    if (get().isLoadingDealRedemptions) {
      return;
    }
    set({
      isLoadingDealRedemptions: true,
      errorDealRedemptions: null,
    });
    try {
      const data = await firebase.firestore
        .collection("redemptions")
        .where("offerID", "==", dealId)
        .where("sponsorID", "==", sponsorId)
        .where("teamID", "==", teamId)
        .get();

      const redemptions = [];
      const recordIds = new Set();
      if (!data.empty) {
        data.forEach((doc) => {
          if (recordIds.has(doc.id)) {
            return;
          }
          recordIds.add(doc.id);
          redemptions.push({ id: doc.id, ...doc.data() });
        });

        set({
          dealRedemptions: {
            [dealId]: redemptions,
          },
        });
      } else {
        set({
          dealRedemptions: {
            [dealId]: [],
          },
        });
      }
    } catch (error) {
      set({ errorDealRedemptions: error });
    } finally {
      set({ isLoadingDealRedemptions: false });
    }
  },
}));

export default useRedemptionsStore;
