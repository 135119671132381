import React, { useState, useEffect } from "react";

import "./DashboardStats.scss";
import "../../../scss/base/typography.scss";
import { FlexContainer } from "../../../assets/fansaves-ui";
import { isMobileScreenSize } from "../../../utilities/core";

import "./DashboardStats.scss";

const DashboardStats = ({ stats, showDifference, admin }) => {
  const renderValue = (value) => {
    if (value >= 0) {
      return `+${value}`;
    } else {
      return value;
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const resize = () => {
    if (isMobileScreenSize(1000)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [isMobile]);

  useEffect(() => {
    resize();
  }, []);

  return (
    <FlexContainer
      className="component-dashboard-stats"
      margin={admin ? "0" : "30px auto"}
      algnItems="center"
      direction="horizontal"
      width="100%"
      justifyContent="space-around"
      responsive
    >
      {stats &&
        stats.map((stat, index) => {
          return (
            <div key={index} className="stat">
              <div className="stat-value rubik-h3">
                {stat.value}{" "}
                {showDifference > 0 && (
                  <span className="stat-difference">
                    ({renderValue(stat.prevValue - stat.value)})
                  </span>
                )}
              </div>
              <div className="stat-label">
                <span
                  className={`stat-label-text ${
                    isMobile ? "public-h2" : "public-h1-small"
                  }`}
                >
                  {stat.label}
                </span>
              </div>
            </div>
          );
        })}
    </FlexContainer>
  );
};

export default DashboardStats;
