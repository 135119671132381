/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./DealRedeemedInfo.scss";
import Countdown from "../Countdown";
import moment from "moment";
import {
  INSTORE_REDEMPTION_TIME_IN_MINUTES,
  DATABASE_DATETIME_FORMAT,
} from "../../utilities/constants";

import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";

function DealRedeemedInfo({ userRedeemedDateTime, isMobile, onRedeemSuccess }) {
  const redemptionTimeExpired = moment(
    userRedeemedDateTime,
    DATABASE_DATETIME_FORMAT
  )
    .add(INSTORE_REDEMPTION_TIME_IN_MINUTES, "minutes")
    .isBefore(moment());

  function renderDealRedeemed() {
    if (redemptionTimeExpired) {
      return (
        <>
          <div className="caption standard-h2">
            {" "}
            <i className="fa fa-clock-o" /> 0:00
          </div>
        </>
      );
    }

    return (
      <>
        <div className="caption">
          <Countdown targetDateTimeString={userRedeemedDateTime} />
        </div>
      </>
    );
  }

  function handleClick() {
    if (!redemptionTimeExpired) {
      onRedeemSuccess();
    }
  }

  return (
    <div className="component-deal-redeemed-info">
      {!redemptionTimeExpired ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className={`deal-redeemed-button button ${
            isMobile ? "primary-cta-small-mobile-btn" : "primary-cta-btn"
          }`}
        >
          View Coupon
        </a>
      ) : (
        <div
          className={`deal-redeemed-button button disabled ${
            isMobile ? "primary-cta-small-mobile-btn" : "primary-cta-btn"
          }`}
        >
          Expired
        </div>
      )}
      {renderDealRedeemed()}
    </div>
  );
}

export default DealRedeemedInfo;
