import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { FlexContainer } from "../Containers";

const Wrapper = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 20px 20px 20px;
`;

const HorizontalBarChart = ({ className, dataSet, labels, options }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  let sum = 0;

  const chartWrapper = useRef(null);

  useEffect(() => {
    if (chartWrapper.current) {
      chartWrapper.current.addEventListener("click", () => {
        const tooltip = document.querySelector("#chartjs-tooltip");
        if (tooltip) {
          tooltip.style.opacity = 1;
        }
      });

      return () => {
        chartWrapper.current.removeEventListener("click", () => {});
      };
    }
  }, [chartWrapper]);

  useEffect(() => {
    document.body.addEventListener("mousewheel", () => {
      const tooltip = document.querySelector("#chartjs-tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
      }
    });
    document.body.addEventListener("touchmove", () => {
      const tooltip = document.querySelector("#chartjs-tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
      }
    });

    return () => {
      document.body.removeEventListener("mousewheel", () => {});
      document.body.removeEventListener("touchmove", () => {});
    };
  }, []);

  const dataDefault = {
    labels: [],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: COLORS.primaryBlue,
        borderColor: COLORS.primaryBlue,
        borderWidth: 1,
        hoverBackgroundColor: COLORS.primaryBlueHover,
        hoverBorderColor: COLORS.primaryBlueHover,
        data: [65, 59, 80, 81, 56, 55],
        borderRadius: 20,
        maxBarThickness: 40,
        categoryPercentage: 0.5,
      },
      {
        label: "Dataset 2",
        backgroundColor: COLORS.chartGrey,
        borderColor: COLORS.chartGrey,
        borderWidth: 1,
        hoverBackgroundColor: COLORS.chartGrey,
        hoverBorderColor: COLORS.chartGrey,
        data: [100, 100, 100, 100, 100, 100],
        maxBarThickness: 40,
        categoryPercentage: 0.5,
      },
    ],
  };

  const optionsDefault = {
    maintainAspectRatio: false,
    cornerRadius: 50,
    responsive: true,
    indexAxis: "y",

    tooltips: {
      enabled: false,
      titleFontSize: 15,
      bodyFontSize: 15,
      callbacks: {
        label: function (tooltipItem, data) {
          if (tooltipItem.datasetIndex === 0) {
            if (dataSet) {
              let label = dataSet[tooltipItem.index];
              let percentage = Math.round((label / sum) * 100);
              return `${label} (${percentage}%)`;
            } else {
              return null;
            }
          }
        },
      },
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          tooltipEl.innerHTML = "<div></div>";
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);

          var innerHtml = "<div class='tooltip-header'>";

          titleLines.forEach(function (title) {
            innerHtml += "<div class='tooltip-title'>" + title + "</div>";
          });
          innerHtml += "</div><div class='tooltip-body'>";

          bodyLines.forEach(function (body, i) {
            innerHtml += "<div class='tooltip-body-item'>";
            innerHtml += "<div class='tooltip-body-item-label'>";
            innerHtml += body;
            innerHtml += "</div>";
            innerHtml += "</div>";
          });
          innerHtml += "</div>";

          var tooltipRoot = tooltipEl.querySelector("div");
          tooltipRoot.innerHTML = innerHtml;
          tooltipRoot.style.backgroundColor = COLORS.primaryBlack;
          tooltipRoot.style.color = COLORS.primaryWhite;
          tooltipRoot.style.borderRadius = "10px";
          tooltipRoot.style.display = "flex";
          tooltipRoot.style.flexDirection = "column";
          tooltipRoot.style.justifyContent = "center";
          tooltipRoot.style.alignItems = "center";
          tooltipRoot.style.padding = "10px";
          tooltipRoot.style.fontSize = "12px";
          tooltipRoot.style.width = "fit-content";
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 0;
        tooltipEl.style.position = "fixed";
        tooltipEl.style.left =
          position.left +
          window.pageXOffset +
          (tooltipModel.caretX / 100) * 50 +
          "px";
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + "px";
      },
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            display: false,
            min: 0,
            max: 100,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          BarPercentage: 0.5,
          ticks: {
            display: false,
          },
        },
      ],
    },
    layout: {
      margin: {
        top: 10,
      },
    },
    legend: {
      display: false,
    },
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          if (context.dataset.label === "Dataset 2") {
            return null;
          }
          return context.chart.data.labels[context.dataIndex];
        },
        align: -58,
        anchor: "start",
        offset: function (context) {
          var labels = context.chart.data.labels;
          if (Object.keys(labels).length > 35) {
            return 17;
          } else if (Object.keys(labels).length > 25) {
            return 18;
          } else if (Object.keys(labels).length > 15) {
            return 20;
          } else if (Object.keys(labels).length > 10) {
            return 22;
          } else {
            return 23;
          }
        },
        padding: function (context) {
          var label = context.chart.data.labels[context.dataIndex];
          var paddingLeft = 0;
          var top = 0;
          if (label.length > 5) {
            paddingLeft = 20;
          }
          if (label.length >= 10) {
            paddingLeft = 35;
          }
          if (label.length >= 15) {
            paddingLeft = 50;
          }
          if (label.length >= 20) {
            paddingLeft = 70;
          }
          if (label.length >= 25) {
            paddingLeft = 90;
          }
          if (label.length >= 30) {
            paddingLeft = 110;
          }
          if (label.length > 40) {
            paddingLeft = 150;
          }

          if (Object.keys(context.chart.data.datasets[0].data).length > 15) {
            top = -10;
          } else {
            top = -8;
          }

          return {
            left: paddingLeft,
            top: top,
          };
        },
        clip: true,
        font: {
          size: "14",
          color: COLORS.primaryBlack,
        },
      },
    },
  };

  const updateBackgroundDataSet = (initialData) => {
    const updatedDataSet = [];
    if (typeof initialData === "object") {
      Object.values(initialData).forEach((value) => {
        updatedDataSet.push(100);
      });
      if (updatedDataSet.length < 6) {
        for (let i = updatedDataSet.length; i < 6; i++) {
          updatedDataSet.push(0);
        }
      }
    }
    return updatedDataSet;
  };

  const updatedDataSetToPercent = (initialData) => {
    const updatedDataSet = [];
    sum = initialData.reduce((a, b) => a + b, 0);
    initialData.forEach((value) => {
      updatedDataSet.push((value / sum) * 100);
    });
    return updatedDataSet;
  };

  const datasetKeyProvider = () => {
    return (Math.random() * 1000000).toString();
  };

  useEffect(() => {
    if ((dataSet, labels)) {
      const tempOptions = { ...optionsDefault, ...options };
      const tempData = { ...dataDefault };
      tempData.datasets[0].data = updatedDataSetToPercent(dataSet);
      tempData.datasets[1].data = updateBackgroundDataSet(dataSet);
      const updatedLabels = [];
      labels.forEach((label) => {
        if (label.length > 30) {
          updatedLabels.push(label.substring(0, 30) + "...");
        } else {
          updatedLabels.push(label);
        }
      });
      tempData.labels = updatedLabels;
      setChartData(tempData);
      setChartOptions(tempOptions);
    }
  }, [dataSet, labels]);

  return (
    <Wrapper className={`horizontal-bar-chart ${className}`} ref={chartWrapper}>
      {chartData && chartOptions && (
        <HorizontalBar
          data={chartData}
          options={chartOptions}
          datasetKeyProvider={datasetKeyProvider}
        />
      )}
    </Wrapper>
  );
};

export default HorizontalBarChart;

HorizontalBarChart.propTypes = {
  className: propTypes.string,
  dataSet: propTypes.array,
  labels: propTypes.array,
  options: propTypes.object,
};
