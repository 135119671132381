// PUBLIC COMPONENTS -------------------------------------
import RulesTester from './views/Pages/RulesTester';
import DefaultLayout from './views/Layouts/DefaultLayout/DefaultLayout';
import PortalLayout from './views/Layouts/PortalLayout/PortalLayout';
import Login from './views/Login';
import Logout from './containers/Logout';

// PUBLIC ROUTES -----------------------------------------------
const RulesTesterRoute = { path: '/rules-tester', name: 'Rules Tester', component: RulesTester };
const LoginRoute = { path: '/login', name: 'Login', component: Login };
const LogoutRoute = { path: '/logout', name: 'Logout', component: Logout };
const PortalRoute = { path: '/portal', name: 'Portal', component: PortalLayout };
const DefaultRoute = { path: '/', name: 'Home', component: DefaultLayout };

/* IMPORTANT!!! More specific routes need to be declared first. Ref: React Router documentation */
const publicUserRoutes = [
  RulesTesterRoute,
  LoginRoute,
  LogoutRoute,
  PortalRoute,
  DefaultRoute,
]

export {
  publicUserRoutes,
};
