/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import LoginModal from "../LoginModal";
import RedeemDealModal from "../RedeemDealModal";
import UnlockExclusiveModal from "../UnlockExclusiveModal";
import FollowOrganizationModal from "../FollowOrganizationModal";
import DealTermsModal from "../DealTermsModal";
import ConfirmContestDealModal from "../ConfirmContestDealModal";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { updateRedemptionsWithDeviceInfo } from "../../utilities/redemption_helpers";

import { DATABASE_DATETIME_FORMAT } from "../../utilities/constants";
import { unlockedExclusives } from "../../utilities/user_helpers";
import { triggeredOfferLimitReached } from "../../utilities/offer_helpers";

import ParticlesConfetti from "../Confetti/ParticlesConfetti";

import cloudFunctionHost from "../CloudFunctionsHost";

import Stripe from "../Stripe";

import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";

class RedeemDealLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      redirectingToStripeCheckout: false,
      showContestConfirmationModal: false,
    };
  }

  redeemDeal = () => {
    const { authUser, deal, firebase, onRedeemSuccess } = this.props;

    // usually a user shouldn't be able to click redeem if triggered offer limit reached but just in case they can...

    if (deal.triggered && triggeredOfferLimitReached(deal)) {
      window.alert(
        "Sorry, this deal has been redeemed the maximum number of times."
      );
      return;
    }

    let redeemDate = moment().format(DATABASE_DATETIME_FORMAT);

    const userRedeemedPath = `/user/${authUser.key}/redeemed/`;
    const userRedeemedRef = firebase.database.ref(userRedeemedPath);
    userRedeemedRef.update({
      [deal.key]: redeemDate,
    });

    const infoToSave = {
      dealID: deal.key,
      userID: authUser.key,
      time: redeemDate,
      teamID: deal.teams[0],
      sponsorID: deal.sponsor.sponsorKey,
      deviceInfo: {
        web: true,
        userAgent: navigator.userAgent,
        deviceName: "",
        modelType: "",
        appVersion: "",
        installationTime: "",
      },
    };

    updateRedemptionsWithDeviceInfo(infoToSave, firebase);

    if (deal.contestDeal) {
      this.showContestConfirmationModal();
    } else {
      this.setState({
        showRedeemDealModal: true,
      });
    }
    if (!deal.contestDeal) {
      onRedeemSuccess(true);
    }
  };

  hideModals = () => {
    const { onShowUnlockExclusiveModal } = this.props;
    this.setState({
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      showDealTermsModal: false,
      showContestConfirmationModal: false,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  showLoginModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;
    this.setState({
      showLoginModal: true,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      showDealTermsModal: false,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  showRedeemDealModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;
    this.setState({
      showLoginModal: false,
      showRedeemDealModal: true,
      showInStoreRedeemModal: true,
      showFollowOrganizationModal: false,
      showDealTermsModal: false,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  showUnlockExclusiveModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;

    this.setState({
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      showDealTermsModal: false,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(true);
    }
  };

  showFollowOrganizationModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;

    this.setState({
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: true,
      showDealTermsModal: false,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  showDealTermsModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;

    this.setState({
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      showDealTermsModal: true,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  showContestConfirmationModal = () => {
    const { onShowUnlockExclusiveModal } = this.props;

    this.setState({
      showLoginModal: false,
      showRedeemDealModal: false,
      showInStoreRedeemModal: false,
      showFollowOrganizationModal: false,
      showDealTermsModal: false,
      showContestConfirmationModal: true,
    });

    if (onShowUnlockExclusiveModal) {
      onShowUnlockExclusiveModal(false);
    }
  };

  unlocked = () => {
    const { authUser, deal } = this.props;

    let unlocked = false;
    deal.teams.forEach((teamKey) => {
      if (authUser && unlockedExclusives(authUser, teamKey)) {
        unlocked = true;
      }
    });

    return unlocked;
  };

  purchased = () => {
    let purchased = false;
    // TODO: Check if purchased

    return purchased;
  };

  followingOrganization = () => {
    const { authUser, deal } = this.props;

    if (authUser) {
      let following = false;

      if (authUser.teams) {
        authUser.teams.forEach((team) => {
          if (deal.teams && deal.teams.includes(team)) {
            following = true;
          }
        });
      }

      return following;
    } else {
      return false;
    }
  };

  enteredContest = () => {
    const { authUser, deal } = this.props;
    let entered = false;
    if (authUser) {
      if (authUser.redeemed) {
        Object.keys(authUser.redeemed).forEach((key) => {
          if (deal.contestDeal && key === deal.key) {
            entered = true;
          }
        });
      }
    }
    return entered;
  };

  enterContest = () => {
    this.redeemDeal();
  };

  handleClick = (e) => {
    e.preventDefault();

    const { authUser, deal, onShowUnlockExclusiveModal } = this.props;

    if (!authUser) {
      this.showLoginModal();
      return;
    }

    if (!this.followingOrganization()) {
      this.showFollowOrganizationModal();
      return;
    }

    if (deal.exclusive && !this.unlocked()) {
      onShowUnlockExclusiveModal(true);
      return;
    }

    if (deal.contestDeal) {
      if (!this.enteredContest()) {
        this.enterContest();
      }
      return;
    }

    if (deal.flashDeal) {
      if (!this.purchased()) {
        this.showDealTermsModal();
      } else {
        // TODO
      }
    } else {
      this.showRedeemDealModal();
      return;
    }
  };

  handleProceed = () => {
    this.getStripeCheckoutSessionAndRedirectToCheckout();
  };

  getStripeCheckoutSessionAndRedirectToCheckout = () => {
    const { deal, firebase } = this.props;

    this.setState({ redirectingToStripeCheckout: true });

    firebase.auth.currentUser.getIdToken().then((token) => {
      fetch(
        `${cloudFunctionHost}/authorizedFunctions/getStripeCheckoutSession?offerId=${deal.key}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseObject) => {
          if (responseObject.checkoutSessionId) {
            Stripe.then((stripe) => {
              console.log(responseObject.checkoutSessionId);
              stripe
                .redirectToCheckout({
                  sessionId: responseObject.checkoutSessionId,
                })
                .then(function (result) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                });
            });
          } else {
            this.setState({ redirectingToStripeCheckout: false });
            window.alert(`Error: ${responseObject.message}`);
          }
        });
    });
  };

  redeemable = () => {
    return true;
  };

  renderRedeem = () => {
    return (
      <span data-notranslate>
        <FormattedMessage id="app.deal.redeem" defaultMessage={"Redeem"} />
      </span>
    );
  };

  render() {
    const {
      deal,
      organization,
      showUnlockExclusiveModal,
      onUnlockExclusiveDeal,
      isMobile,
    } = this.props;

    const {
      showLoginModal,
      showRedeemDealModal,
      showDealTermsModal,
      showFollowOrganizationModal,
      showContestConfirmationModal,
      redirectingToStripeCheckout,
    } = this.state;

    const needToUnlock = deal.exclusive && !this.unlocked();
    const needToPurchase = deal.flashDeal && !this.purchased();
    const needToConfirmContest = deal.contestDeal && !this.enteredContest();
    const confirmedContest = deal.contestDeal && this.enteredContest();

    return (
      <>
        <a
          href="#"
          onClick={this.handleClick}
          className={`component-redeem-deal-link button ${
            isMobile ? "primary-cta-small-mobile-btn" : "primary-cta-btn"
          } ${needToPurchase ? "purchase" : ""} ${
            confirmedContest ? "disabled contest" : ""
          } ${needToConfirmContest ? "contest" : ""}`}
        >
          {needToPurchase
            ? "Purchase"
            : needToConfirmContest
            ? "Enter Contest"
            : confirmedContest
            ? "Entered"
            : this.renderRedeem()}
        </a>
        {showLoginModal && (
          <LoginModal
            onClose={this.hideModals}
            returnToCurrentPageAfterOnboarding={true}
          />
        )}
        {showRedeemDealModal && (
          <RedeemDealModal
            onClose={this.hideModals}
            onConfirm={this.redeemDeal}
            deal={deal}
            organization={organization}
            isMobile={isMobile}
            renderRedeem={this.renderRedeem}
          />
        )}

        {showUnlockExclusiveModal && (
          <UnlockExclusiveModal
            deal={deal}
            onClose={this.hideModals}
            isMobile={isMobile}
            organization={organization}
            onUnlockExclusiveDeal={onUnlockExclusiveDeal}
          />
        )}

        {showFollowOrganizationModal && (
          <FollowOrganizationModal
            organizationIds={deal.teams}
            onClose={this.hideModals}
            isMobile={isMobile}
            deal={deal}
          />
        )}

        {showDealTermsModal && (
          <DealTermsModal
            deal={deal}
            onProceed={this.handleProceed}
            onClose={this.hideModals}
            proceeding={redirectingToStripeCheckout}
          />
        )}

        {showContestConfirmationModal && (
          <ConfirmContestDealModal
            deal={deal}
            onClose={this.hideModals}
            onConfirm={this.hideModals}
            organization={organization}
            isMobile={isMobile}
            backgroundColor="blue"
          />
        )}
        {showContestConfirmationModal && <ParticlesConfetti />}
      </>
    );
  }
}

RedeemDealLink.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default withFirebase(withAuthentication(RedeemDealLink));
