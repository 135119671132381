const messages = {
  en: {
    "app.fans": "Fans",
    "app.deal.redeem": "Redeem",
    "app.team.following": "Following",
    "app.deal.triggered": "Triggered",
    "app.deal.not.triggered": "Not Triggered",
    "app.deal.exclusive": "Exclusive Deal",
    "app.deal.flash": "Flash Deal",
    "app.deal.contest": "Contest",
    "app.category.baseball": "Baseball",
    "app.category.basketball": "Basketball",
    "app.category.volleyball": "Volleyball",
    "app.category.hockey": "Hockey",
    "app.category.bias": "BIAs",
    "app.category.allFanPages": "All FanPages",
    "app.signout": "Sign Out",
  },
  fr: {
    "app.fans": "Supporters",
    "app.deal.redeem": "Réclamer",
    "app.team.following": "Suivi(e)",
    "app.deal.triggered": "Déclenché",
    "app.deal.not.triggered": "Pas Déclenché",
    "app.deal.exclusive": "Offre Exclusive",
    "app.deal.flash": "Offre Éclair",
    "app.deal.contest": "Concours",
    "app.category.baseball": "Baseball",
    "app.category.basketball": "Basketball",
    "app.category.hockey": "Hockey",
    "app.category.volleyball": "Volleyball",
    "app.category.bias": "BIAs",
    "app.category.allFanPages": "Tous les FanPages",
    "app.signout": "Déconnexion",
  },
};

export default messages;
