/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../../containers/Firebase";
import { withRouter } from "react-router-dom";
import { withAuthentication } from "../../../../../containers/Session";
import DashboardDealCard from "../../../../../containers/DashboardDealCard";
import {
  FlexContainer,
  LineChart,
  SpinLoader,
  DashboardTitle,
} from "../../../../../assets/fansaves-ui";
import "../../../../../scss/base/typography.scss";
import { DashboardHeader } from "../../../../../containers/UIKit";

import {
  getRedemptionChartData,
  getImpressionsChartData,
  mapUserDataforCharts,
} from "../../../../../utilities/dashboard_helpers";

import useRedemptionsStore from "../../../../../stores/redemptionsStore";
import useTeamsStore from "../../../../../stores/teamsStore";
import useSponsorsStore from "../../../../../stores/sponsorsStore";

import DashboardRedemptionsTable from "../../../../../containers/DashboardRedemptionsTable";
import DashboardChartContainer from "../../../../../containers/DashboardChartContainer";
import DashboardRedemptionAnalytics from "../../../../../containers/DashboardRedemptionAnalytics";

const Show = ({ firebase, match, authUser, history }) => {
  const dealId = match.params.deal_id;
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const sponsorFromStore = useSponsorsStore((state) => state.sponsor);
  const fetchSponsor = useSponsorsStore((state) => state.fetchSponsor);

  const [deal, setDeal] = useState(null);
  const [sponsor, setSponsor] = useState(null);
  const [team, setTeam] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [impressions, setImpressions] = useState(null);
  const [genderChartData, setGenderChartData] = useState(null);
  const [ageChartData, setAgeChartData] = useState(null);
  const [impressionsChartData, setImpressionsChartData] = useState(null);
  const [redemptionsChartData, setRedemptionsChartData] = useState(null);
  const [locationChartData, setLocationChartData] = useState(null);
  const [selectedLocationChartData, setSelectedLocationChartData] =
    useState(null);
  const [selectedRedemtionFilter, setSelectedRedemtionFilter] =
    useState("7days");
  const [selectedImpressionFilter, setSelectedImpressionFilter] =
    useState("7days");
  const [impressionsFilterDaysAdjustment, setImpressionsFilterDaysAdjustment] =
    useState(0);
  const [redemptionsFilterDaysAdjustment, setRedemptionsFilterDaysAdjustment] =
    useState(0);
  const [heatMapData, setHeatMapData] = useState(null);

  useEffect(() => {
    const sponsorId = deal && deal.sponsor && deal.sponsor.sponsorKey;
    if (sponsorFromStore && sponsorId && sponsorFromStore[sponsorId]) {
      setSponsor(sponsorFromStore[sponsorId]);
    }
  }, [sponsorFromStore, deal]);

  useEffect(() => {
    const teamId = deal && deal.teams && deal.teams[0];
    if (teamFromStore && teamId && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, deal]);

  useEffect(() => {
    if (!redemptionsFromEvents) {
      fetchRedemptions(firebase);
    }
  }, [fetchRedemptions, firebase]);

  useEffect(() => {
    const teamId = deal && deal.teams && deal.teams[0];
    if ((teamId && !team) || (team && teamId && team.key !== teamId)) {
      fetchTeam(firebase, teamId);
    }
  }, [deal, fetchTeam, firebase, team]);

  useEffect(() => {
    if (deal) {
      if (
        !sponsor ||
        (deal.sponsor && deal.sponsor.sponsorKey !== sponsor.key)
      ) {
        fetchSponsor(firebase, deal.sponsor && deal.sponsor.sponsorKey);
      }
    }
  }, [deal, fetchSponsor, firebase, sponsor]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        margin="30px auto"
        height="100px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };
  useEffect(() => {
    if (redemptionsFromEvents && deal) {
      const filteredRedemptions = redemptionsFromEvents.filter((redemption) => {
        return (
          redemption.offerID === dealId &&
          deal.sponsor &&
          deal.sponsor.sponsorKey === redemption.sponsorID &&
          deal.teams &&
          deal.teams[0] === redemption.teamID
        );
      });

      setRedemptions(filteredRedemptions);
    }
  }, [redemptionsFromEvents, deal, dealId]);

  useEffect(() => {
    if (redemptions && deal) {
      updateRedemptionsChartData();
    }
  }, [
    redemptions,
    selectedRedemtionFilter,
    redemptionsFilterDaysAdjustment,
    deal,
  ]);

  const loadDealData = () => {
    const dealPath = `/offer/${dealId}`;
    const dealRef = firebase.database.ref(dealPath);
    dealRef.on("value", (snapshot) => {
      const deal = snapshot.val();
      deal.key = snapshot.key;
      setDeal(deal);
    });
  };

  const updateRedemptionsChartData = () => {
    if (redemptions && deal) {
      let dealType;
      if (
        deal.active &&
        !deal.triggered &&
        !deal.flashDeal &&
        !deal.exclusive &&
        !deal.contestDeal &&
        !deal.hiddenDeal
      ) {
        dealType = "public";
      } else if (deal.triggered && !deal.contestDeal) {
        dealType = "triggered";
      } else if (deal.flashDeal) {
        dealType = "flashDeal";
      } else if (deal.exclusive) {
        dealType = "exclusive";
      } else if (deal.contestDeal) {
        dealType = "contest";
      } else if (deal.hiddenDeal) {
        dealType = "hiddenDeal";
      } else {
        dealType = "public";
      }

      const redemptionsData = getRedemptionChartData(
        redemptions,
        selectedRedemtionFilter,
        redemptionsFilterDaysAdjustment,
        dealType
      );

      setRedemptionsChartData(redemptionsData);
    }
  };

  const mapImpressions = () => {
    const updatedImpressions = [];
    if (deal) {
      const { impressions } = deal;
      if (impressions) {
        updatedImpressions.push(Object.values(impressions));
      }
    }

    const flattenedImpressions = [].concat.apply([], updatedImpressions);

    setImpressions(flattenedImpressions);
  };

  const updateImpressionsChartData = () => {
    if (impressions) {
      const impressionsData = getImpressionsChartData(
        impressions,
        selectedImpressionFilter,
        impressionsFilterDaysAdjustment
      );

      setImpressionsChartData(impressionsData);
    }
  };

  const mapUserData = () => {
    if (redemptions) {
      const { genderData, ageData, userLocationMap, userStateMap } =
        mapUserDataforCharts(redemptions);

      let selectedUserLocationMap = {};

      setGenderChartData(genderData);
      setAgeChartData(ageData);

      if (Object.keys(userLocationMap).length > 5) {
        selectedUserLocationMap = Object.keys(userLocationMap)
          .slice(0, 6)
          .reduce((obj, key) => {
            obj[key] = userLocationMap[key];
            return obj;
          }, {});
      }

      setHeatMapData(userStateMap);

      setSelectedLocationChartData(selectedUserLocationMap);
      setLocationChartData(userLocationMap);
    }
  };

  useEffect(() => {
    if (dealId) {
      loadDealData();
    }
  }, [dealId]);

  useEffect(() => {
    if (deal) {
      mapImpressions();
    }
  }, [deal]);

  useEffect(() => {
    if (redemptions) {
      mapUserData();
    }
  }, [redemptions]);

  useEffect(() => {
    if (impressions) {
      updateImpressionsChartData();
    }
  }, [impressions, selectedImpressionFilter, impressionsFilterDaysAdjustment]);

  useEffect(() => {
    if (selectedRedemtionFilter) {
      setRedemptionsFilterDaysAdjustment(0);
    }
    if (selectedImpressionFilter) {
      setImpressionsFilterDaysAdjustment(0);
    }
  }, [selectedRedemtionFilter, selectedImpressionFilter]);

  useEffect(() => {
    if (redemptionsFilterDaysAdjustment > 0) {
      setSelectedRedemtionFilter("7days");
    }
    if (impressionsFilterDaysAdjustment > 0) {
      setSelectedImpressionFilter("7days");
    }
  }, [redemptionsFilterDaysAdjustment, impressionsFilterDaysAdjustment]);

  return (
    <div className="component-sponsor-deal-show">
      <FlexContainer
        className="sponsor-deal-show-header"
        alignItems="flex-start"
        width="100%"
        gap="30px"
        margin="30px auto"
      >
        <DashboardTitle
          title="Deal"
          justifyContent="flex-start"
          className="standard-h5 blue"
          onBack={() => history.goBack()}
        />
        <FlexContainer
          direction="horizontal"
          justifyContent="space-between"
          responsive
          mobileDirection="column-reverse"
        >
          {sponsor ? (
            <>
              <DashboardHeader
                logo={sponsor.picture}
                name={sponsor.name}
                email={sponsor.displayEmail}
                description={sponsor.description}
                profileUrl={sponsor.url}
                hideEl
              />
              {team && (
                <DashboardHeader
                  logo={team.logo}
                  name={team.name}
                  email={team.displayEmail}
                  profileUrl={team.website}
                  hideEl
                  secondaryHeader
                />
              )}
            </>
          ) : (
            team && (
              <DashboardHeader
                logo={team.logo}
                name={team.name}
                email={team.displayEmail}
                profileUrl={team.website}
                hideEl
                className="logo-deals-show-header"
              />
            )
          )}
        </FlexContainer>
        <FlexContainer
          className="redemptions-count rubik-h3"
          direction="horizontal"
          justifyContent="flex-start"
          alignItems="center"
          width="fit-content"
        >
          {deal && deal.contestDeal ? (
            <>
              Number of Entries:{" "}
              {redemptions
                ? redemptions.filter((r) => r.transactionType === "contest")
                    .length
                : renderLoading()}
            </>
          ) : (
            <>
              Number of Redemptions:{" "}
              {redemptions
                ? redemptions.filter((r) => r.transactionType === "redemption")
                    .length
                : renderLoading()}
            </>
          )}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer className="sponsor-deal-show-card" alignItems="flex-start">
        {deal ? (
          <DashboardDealCard deal={deal} hideViewButton dealId={dealId} />
        ) : (
          renderLoadingContainer()
        )}
      </FlexContainer>
      <FlexContainer
        className="sponsor-deal-show-redemptions"
        margin="30px auto"
      >
        {redemptions && sponsor ? (
          <DashboardRedemptionsTable
            title="Customer Demographics"
            redemptions={redemptions}
            limit={5}
            showAllClick={(e) => {
              e.preventDefault();
              history.push(
                `/portal/sponsors/${sponsor.key}/deals/${dealId}/redemptions`
              );
            }}
          />
        ) : (
          renderLoadingContainer()
        )}
      </FlexContainer>

      <DashboardChartContainer
        title="Redemptions"
        onSelectFilter={setSelectedRedemtionFilter}
        onFilterDaysAdjustment={setRedemptionsFilterDaysAdjustment}
        filterDaysAdjustment={redemptionsFilterDaysAdjustment}
        renderChart={() => {
          if (redemptionsChartData) {
            return (
              <LineChart
                dataSet={redemptionsChartData}
                showLegend
                filter={selectedRedemtionFilter}
                filterDaysAdjustment={redemptionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      <DashboardChartContainer
        title="Impressions"
        onSelectFilter={setSelectedImpressionFilter}
        onFilterDaysAdjustment={setImpressionsFilterDaysAdjustment}
        filterDaysAdjustment={impressionsFilterDaysAdjustment}
        renderChart={() => {
          if (impressionsChartData) {
            return (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={selectedImpressionFilter}
                filterDaysAdjustment={impressionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      {redemptions ? (
        <DashboardRedemptionAnalytics
          redemptions={redemptions}
          genderChartData={genderChartData}
          ageChartData={ageChartData}
          locationChartData={locationChartData}
          selectedLocationChartData={selectedLocationChartData}
          heatMapData={heatMapData}
        />
      ) : (
        renderLoadingContainer()
      )}
    </div>
  );
};

export default withFirebase(withRouter(withAuthentication(Show)));
