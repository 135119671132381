import React from "react";
import propTypes from "prop-types";
import { Icon } from "@iconify/react";
import styled, { css } from "styled-components/macro";
import { COLORS } from "../colors";

import "../../../scss/base/typography.scss";
import "../../../scss/base/colorswatches.scss";

const Button = styled.button`
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &:focus-visible {
    outline: none;
  }
  ${({ type }) => {
    switch (type) {
      case "green":
        return css`
          background-color: ${COLORS.primaryGreen};
          div {
            color: ${COLORS.primaryWhite};
          }

          &:hover {
            background-color: ${COLORS.primaryGreenHover};
          }
        `;
      case "shadow-green":
        return css`
          background-color: ${COLORS.primaryGreen};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          div {
            color: ${COLORS.primaryWhite};
          }

          &:hover {
            background-color: ${COLORS.primaryGreenHover};
          }
        `;
      case "white":
        return css`
          background-color: ${COLORS.primaryWhite};
          border: 1px solid ${COLORS.primaryBlue};

          div {
            color: ${COLORS.primaryBlue};
          }
          &:hover {
            background-color: ${COLORS.lightGrey};
          }
        `;
      case "shadow-white":
        return css`
          background-color: ${COLORS.primaryWhite};
          border: 1px solid ${COLORS.primaryBlue};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          div {
            color: ${COLORS.primaryBlue};
          }
          &:hover,
          &:focus {
            background-color: ${COLORS.lightGrey};
          }
        `;

      case "shadow-white-green":
        return css`
          background-color: ${COLORS.primaryWhite};
          border: 1px solid ${COLORS.primaryGreen};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          div {
            color: ${COLORS.primaryGreen};
          }
          &:hover,
          &:focus {
            background-color: ${COLORS.primaryGreen};
            outline: none;
            div {
              color: ${COLORS.primaryWhite};
            }
          }
        `;

      case "shadow-white-red":
        return css`
          background-color: ${COLORS.primaryWhite};
          border: 1px solid ${COLORS.chartRed};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          div {
            color: ${COLORS.chartRed};
          }
          &:hover,
          &:focus {
            background-color: ${COLORS.chartRed};
            outline: none;
            div {
              color: ${COLORS.primaryWhite};
            }
          }
        `;

      default:
        return css`
          background-color: ${COLORS.primaryBlue};
          border-color: ${COLORS.primaryBlue};
          div {
            color: ${COLORS.primaryWhite};
          }

          &:hover,
          &:focus {
            background-color: ${COLORS.primaryBlueHover};
            border-color: ${COLORS.primaryBlueHover};
          }
        `;
    }
  }};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${({ disabled, type }) => {
    if (disabled) {
      switch (type) {
        case "green":
          return css`
            background-color: ${COLORS.primaryGreenHover};
          `;
        case "shadow-green":
          return css`
            background-color: ${COLORS.primaryGreenHover};
          `;
        case "white":
          return css`
            background-color: ${COLORS.lightGrey};
          `;
        case "shadow-white":
          return css`
            background-color: ${COLORS.lightGrey};
          `;
        case "shadow-white-green":
          return css`
            background-color: ${COLORS.lightGrey};
          `;
        case "shadow-white-red":
          return css`
            background-color: ${COLORS.lightGrey};

            &:hover,
            &:focus {
              background-color: ${COLORS.lightGrey};
              outline: none;
              div {
                color: ${COLORS.chartRed};
              }
            }
          `;
        default:
          return css`
            background-color: ${COLORS.primaryBlueHover};
            border-color: ${COLORS.primaryBlueHover};
          `;
      }
    }
  }}
  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          padding: 5px 2px;
          width: 93px;
        `;
      case "medium":
        return css`
          padding: 6px 14px;
          width: fit-content;
        `;
      default:
        return css`
          padding: 14px 2px;
          width: ${(props) => (props.width ? props.width : "190px")};
        `;
    }
  }};
  ${({ move }) => {
    switch (move) {
      case "left":
        return css`
          align-self: flex-start;
        `;
      case "right":
        return css`
          align-self: flex-end;
        `;
      default:
        return css`
          align-self: "";
        `;
    }
  }};

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

const DashboardButton = ({
  icon,
  title,
  onClick,
  type,
  size,
  disabled,
  href = "",
  className,
  typography,
  move,
  width,
  disabledMessage,
}) => {
  return (
    <Button
      as={href ? "a" : "button"}
      onClick={!disabled ? onClick : null}
      className={`${typography ? typography : "inter-b1"} ${className}`}
      type={type}
      size={size}
      disabled={disabled}
      href={disabled ? null : href}
      move={move}
      width={width}
      title={disabledMessage}
    >
      <div className="title">
        {icon && <Icon icon={icon} />}
        {title}
      </div>
    </Button>
  );
};

export default DashboardButton;

DashboardButton.propTypes = {
  icon: propTypes.string,
  title: propTypes.oneOfType([propTypes.string, propTypes.element]),
  onClick: propTypes.func,
  type: propTypes.string,
  size: propTypes.string,
  disabled: propTypes.bool,
  href: propTypes.string,
  className: propTypes.string,
  typography: propTypes.string,
  move: propTypes.string,
  width: propTypes.string,
};
