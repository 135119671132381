import React, { useEffect, useMemo } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import { Link } from "react-router-dom";
import { CSVExport } from "../../../../assets/fansaves-ui";
import {
  getLocation,
  calculateAge,
} from "../../../../utilities/dashboard_helpers";
import { calculateImpressionsForTeams } from "../../../../utilities/dashboard_helpers";
import moment from "moment";

import "./List.scss";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useUsersStore from "../../../../stores/usersStore";
import useTeamsStore from "../../../../stores/teamsStore";
import useOffersStore from "../../../../stores/offersStore";
import useSponsorsStore from "../../../../stores/sponsorsStore";
import useExclusiveCodesStore from "../../../../stores/exclusiveCodesStore";
import useAccountDeleteStore from "../../../../stores/accountDeleteStore";

function List({ firebase }) {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const users = useUsersStore((state) => state.users);
  const fetchUsers = useUsersStore((state) => state.fetchUsers);

  const teams = useTeamsStore((state) => state.teams);
  const fetchTeams = useTeamsStore((state) => state.fetchTeams);

  const offers = useOffersStore((state) => state.offersArray);
  const fetchOffers = useOffersStore((state) => state.fetchOffers);

  const sponsors = useSponsorsStore((state) => state.sponsors);
  const fetchSponsors = useSponsorsStore((state) => state.fetchSponsors);

  const exclusiveCodesTotals = useExclusiveCodesStore(
    (state) => state.exclusiveCodesTotals
  );

  const fetchExclusiveCodesTotalsFromFirestore = useExclusiveCodesStore(
    (state) => state.fetchExclusiveCodesTotalsFromFirestore
  );

  const accountDeleteEvents = useAccountDeleteStore(
    (state) => state.accountDeleteEvents
  );
  const fetchAccountDeleteEvents = useAccountDeleteStore(
    (state) => state.fetchAccountDeleteEvents
  );

  useEffect(() => {
    if (!redemptionsFromEvents) {
      fetchRedemptions(firebase);
    }
  }, [fetchRedemptions, firebase, redemptionsFromEvents]);

  useEffect(() => {
    if (!users) {
      fetchUsers(firebase);
    }
  }, [fetchUsers, firebase, users]);

  useEffect(() => {
    if (!teams) {
      fetchTeams(firebase);
    }
  }, [fetchTeams, firebase, teams]);

  useEffect(() => {
    if (!offers) {
      fetchOffers(firebase);
    }
  }, [fetchOffers, firebase, offers]);

  useEffect(() => {
    if (!sponsors) {
      fetchSponsors(firebase);
    }
  }, [fetchSponsors, firebase, sponsors]);

  useEffect(() => {
    if (!exclusiveCodesTotals) {
      fetchExclusiveCodesTotalsFromFirestore(firebase);
    }
  }, [fetchExclusiveCodesTotalsFromFirestore, firebase, exclusiveCodesTotals]);

  useEffect(() => {
    if (!accountDeleteEvents) {
      fetchAccountDeleteEvents(firebase);
    }
  }, [fetchAccountDeleteEvents, firebase, accountDeleteEvents]);

  const generateHeaders = useMemo(() => {
    return [
      { label: "#", key: "index" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Age", key: "age" },
      { label: "Gender", key: "gender" },
      { label: "Location", key: "location" },
      { label: "Account Created", key: "accountCreated" },
      { label: "Email Verified", key: "emailVerified" },
    ];
  }, []);

  const generateData = useMemo(() => {
    if (!users) return [];
    return users.reduce((acc, user) => {
      const { email, createdAt, gender, zip, dob, name, emailVerified } = user;

      const age = calculateAge(dob);
      const { city } = getLocation(zip);

      const userData = {
        index: acc.length + 1,
        name: name || "N/A",
        email: email || "N/A",
        age: age || "N/A",
        gender: gender || "N/A",
        location: city || "N/A",
        accountCreated: createdAt
          ? moment(createdAt).format("YYYY/MM/DD HH:mm A")
          : "N/A",
        emailVerified: emailVerified ? "Yes" : "No",
      };

      acc.push(userData);
      return acc;
    }, []);
  }, [users]);

  const generateHeadersForTeamsReport = useMemo(() => {
    return [
      { label: "#", key: "index" },
      { label: "Team Name", key: "teamName" },
      { label: "Team Redemptions", key: "teamRedemptions" },
      { label: "Team Contest Entries", key: "teamContestEntries" },
      { label: "Team Followers", key: "teamFollowers" },
      { label: "Team Impressions", key: "teamImpressions" },
      { label: "Team Sponsors", key: "teamSponsors" },
      { label: "Team Offers", key: "teamOffers" },
      { label: "Team Exclusive Codes", key: "teamExclusiveCodes" },
    ];
  }, []);

  const generateDataForTeamsReport = useMemo(() => {
    if (!teams || !redemptionsFromEvents || !offers || !sponsors) {
      return;
    }

    const impressionsForTeams = calculateImpressionsForTeams(
      sponsors,
      offers,
      teams
    );

    const data = teams.reduce((acc, team) => {
      const { name, followers, sponsors, key } = team;

      const teamFollowers = (followers && Object.keys(followers).length) || 0;

      const teamSponsors =
        sponsors && sponsors.length > 0 ? sponsors.length : 0;

      const teamExclusiveCodes =
        exclusiveCodesTotals && exclusiveCodesTotals[key]
          ? exclusiveCodesTotals[key].total || 0
          : 0;

      const teamImpressions = impressionsForTeams.find(
        (impression) => impression.teamKey === team.key
      );

      const teamRedemptions = redemptionsFromEvents.filter(
        (redemption) =>
          redemption.teamID === team.key &&
          redemption.transactionType === "redemption"
      );

      const teamContestEntries = redemptionsFromEvents.filter(
        (redemption) =>
          redemption.teamID === team.key &&
          redemption.transactionType === "contest"
      );

      const teamOffers =
        offers &&
        offers.filter((offer) => offer.teams && offer.teams.includes(team.key))
          .length;

      const teamData = {
        index: acc.length + 1,
        teamName: name,
        teamRedemptions: teamRedemptions ? teamRedemptions.length : 0,
        teamContestEntries: teamContestEntries ? teamContestEntries.length : 0,
        teamFollowers,
        teamImpressions: teamImpressions ? teamImpressions.impressions : 0,
        teamSponsors,
        teamOffers,
        teamExclusiveCodes,
      };

      acc.push(teamData);
      return acc;
    }, []);

    const totals = {
      index: "Total",
      teamName: "Total",
      teamRedemptions: data.reduce(
        (total, teamData) => total + teamData.teamRedemptions,
        0
      ),
      teamContestEntries: data.reduce(
        (total, teamData) => total + teamData.teamContestEntries,
        0
      ),
      teamFollowers: data.reduce(
        (total, teamData) => total + teamData.teamFollowers,
        0
      ),
      teamImpressions: data.reduce(
        (total, teamData) => total + teamData.teamImpressions,
        0
      ),
      teamSponsors: data.reduce(
        (total, teamData) => total + teamData.teamSponsors,
        0
      ),
      teamOffers: data.reduce(
        (total, teamData) => total + teamData.teamOffers,
        0
      ),
      teamExclusiveCodes: data.reduce(
        (total, teamData) => total + teamData.teamExclusiveCodes,
        0
      ),
    };

    data.unshift(totals);

    return data;
  }, [teams, redemptionsFromEvents, offers, sponsors, exclusiveCodesTotals]);

  const generateHeadersForAccountDeletions = useMemo(() => {
    return [
      { label: "#", key: "index" },
      { label: "User Key", key: "userKey" },
      { label: "Email", key: "email" },
      { label: "Account Created", key: "accountCreated" },
      { label: "Account Deleted", key: "accountDeletedAt" },
    ];
  }, []);

  const generateDataForAccountDeletions = useMemo(() => {
    if (!accountDeleteEvents) return [];
    return accountDeleteEvents.reduce(
      (acc, event, index) => {
        const { userKey, createdAt } = event;
        const user = users && users.find((user) => user.key === userKey);
        const { email, createdAt: userCreatedAt } = user || {};

        const accountDeletionData = {
          index: index + 1,
          userKey: userKey || "N/A",
          email: email || "N/A",
          accountCreated: userCreatedAt
            ? moment(userCreatedAt).format("YYYY/MM/DD HH:mm A")
            : "N/A",
          accountDeletedAt: createdAt
            ? moment(createdAt).format("YYYY/MM/DD HH:mm A")
            : "N/A",
        };

        acc.push(accountDeletionData);
        return acc;
      },
      [
        {
          index: "Total",
          userKey: accountDeleteEvents.length,
          accountDeletedAt: "",
        },
      ]
    );
  }, [accountDeleteEvents, users]);

  return (
    <div className="views-portal-reports-list">
      <h1>Reports</h1>

      <ul>
        <li>
          Organization Followers:{" "}
          <Link to="/portal/reports/followers">Open Report</Link>
        </li>
        <li>
          Users:{" "}
          {users && users.length > 0 && (
            <CSVExport
              data={generateData}
              headers={generateHeaders}
              filename={`FanSaves-users ${moment().format("YYYY-MM-DD")}`}
              className="link"
            >
              Download Report
            </CSVExport>
          )}
        </li>
        <li>
          Teams:{" "}
          {teams && redemptionsFromEvents && users && offers && sponsors && (
            <CSVExport
              data={generateDataForTeamsReport}
              headers={generateHeadersForTeamsReport}
              filename={`FanSaves-teams ${moment().format("YYYY-MM-DD")}`}
              className="link"
            >
              Download Report
            </CSVExport>
          )}
        </li>
        <li>
          Account Deletions:{" "}
          {accountDeleteEvents && accountDeleteEvents.length > 0 && (
            <CSVExport
              data={generateDataForAccountDeletions}
              headers={generateHeadersForAccountDeletions}
              filename={`FanSaves-account-deletions ${moment().format(
                "YYYY-MM-DD"
              )}`}
              className="link"
            >
              Download Report
            </CSVExport>
          )}
        </li>
      </ul>
    </div>
  );
}

export default React.memo(withFirebase(List));
